import { Button, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { FlagImage } from 'src/shared';

export const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const items: MenuProps['items'] = [
    {
      key: 'lt',
      icon: <FlagImage name="lt" />,
    },
    {
      key: 'gb',
      icon: <FlagImage name="gb" />,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
        onClick: (item) => {
          i18n.changeLanguage(item.key);
          localStorage.setItem('language', item.key);
        },
      }}
      placement="bottom"
    >
      <Button
        size="large"
        type="text"
        icon={<FlagImage name={i18n.language} />}
      />
    </Dropdown>
  );
};
