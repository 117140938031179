import { Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { components } from '../../interfaces';

interface IProps {
  data?: any;
  onClick: (id: number) => void;
}

export const TableUsers = ({ data, onClick }: IProps) => {
  const { t } = useTranslation();
  const columns: TableProps<components['schemas']['UserResource']>['columns'] = [
    {
      title: t('Users.Labels.Name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('Users.Labels.Surname'),
      key: 'surname',
      dataIndex: 'surname',
    },
    {
      title: t('Users.Labels.Email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('Users.Labels.RegistrationDate'),
      key: 'registrationDate',
      dataIndex: 'registrationDate',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => onClick(record.id),
        };
      }}
    />
  );
};
