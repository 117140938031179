import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { App, Button, Flex, Table, TableProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { components } from '../../interfaces';
import { servicesSelections } from '../../services';
import { FieldsInput, Form } from '../../shared';

interface IProps {
  slug: string;
}

export const TableSelections = ({ slug }: IProps) => {
  const [isEditable, setIsEditable] = useState(false);
  const { isFetching, data, refetch } = servicesSelections.getSelection(slug);
  const { modal } = App.useApp();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    setIsEditable(false);
    refetch();
  };

  const handleDelete = (item: components['schemas']['OptionResource']) => {
    modal.confirm({
      title: t('Confirms.Titles.DeleteSelection'),
      content: t('Confirms.Contents.DeleteSelection{{value}}', { value: item.label }),
    });
  };

  const columns: TableProps<components['schemas']['OptionResource']>['columns'] = [
    {
      title: t('TableSelections.Labels.Title'),
      dataIndex: 'label',
      render: (value, _row, index) =>
        isEditable ? (
          <FieldsInput
            wrapperClassName="mb-0"
            name={`options.${index}.label`}
            shouldUnregister={false}
          />
        ) : (
          value
        ),
    },
    {
      title: t('TableSelections.Labels.Options'),
      render: (_: any, row: any) =>
        row.readOnly ? null : (
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(row)}
          />
        ),
      align: 'right',
    },
  ];

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={data?.data ?? {}}
      >
        {({ formState }) => (
          <>
            <Table
              className="mb-3"
              rowKey="value"
              dataSource={data?.data.options ?? []}
              columns={columns}
              loading={isFetching}
              pagination={false}
              size="small"
            />
            <Flex justify="end">
              <Button.Group>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setIsEditable((prevState) => !prevState)}
                  disabled={formState.isSubmitting}
                >
                  {isEditable ? t('TableSelections.Buttons.Cancel') : t('TableSelections.Buttons.Edit')}
                </Button>
                {isEditable && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formState.isDirty}
                    loading={formState.isSubmitting}
                  >
                    {t('TableSelections.Buttons.Submit')}
                  </Button>
                )}
              </Button.Group>
            </Flex>
          </>
        )}
      </Form>
    </>
  );
};
