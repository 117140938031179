import { Collapse, CollapseProps } from 'antd';

import { components } from '../../interfaces';
import { TableSelections } from '../TableSelections';

interface IProps {
  data?: components['schemas']['SelectionIndexResource'][];
}

export const CollapseSelections = ({ data = [] }: IProps) => {
  const items: CollapseProps['items'] = data.map((item) => ({
    key: item.slug,
    label: item.name,
    children: <TableSelections slug={item.slug} />,
  }));

  return (
    <Collapse
      className="mb-4"
      items={items}
      destroyInactivePanel={true}
    />
  );
};
