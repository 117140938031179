import Pusher from 'pusher-js';
import { useEffect, useRef, useState } from 'react';

interface PusherConfig {
  channelName: string;
  eventName: string;
}

export const usePusher = <T>(
  { channelName, eventName }: PusherConfig = {
    channelName: '',
    eventName: '',
  },
) => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const pusherRef = useRef<Pusher | null>(null);
  // @ts-ignore
  const channelRef = useRef<Pusher.Channel | null>(null);

  useEffect(() => {
    if (!channelName || !eventName) {
      setError(new Error('Missing required Pusher configuration'));

      return;
    }

    try {
      pusherRef.current = new Pusher('09cacc24402fc8430eda', {
        cluster: 'eu',
        authEndpoint: undefined,
        channelAuthorization: undefined,
      });
      Pusher.logToConsole = true;
      channelRef.current = pusherRef.current.subscribe(channelName);
      channelRef.current.bind(eventName, (newData: T) => {
        setData(newData);
      });
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to initialize Pusher'));
    }

    return () => {
      if (channelRef.current) {
        channelRef.current.unbind(eventName);
      }

      if (pusherRef.current) {
        pusherRef.current.unsubscribe(channelName);
        pusherRef.current.disconnect();
      }
    };
  }, [channelName, eventName]);

  return { data, error };
};
