import { DeleteOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, Button, PaginationProps, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '../../consts';
import { components } from '../../interfaces';
import { servicesWorkflows } from '../../services';
import { Date } from '../../shared';
import { TranslateHTML } from '../TranslateHTML';

interface IProps {
  data?: components['schemas']['WorkflowIndexCollection'];
  isLoading: boolean;
  onClick: (data: components['schemas']['WorkflowIndexResource']) => void;
  pagination: PaginationProps;
  onPaginationChange: (pagination: PaginationProps) => void;
}

export const TableWorkflows = ({ data, isLoading, onClick, pagination, onPaginationChange }: IProps) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const { mutateAsync } = servicesWorkflows.deleteWorkflow();

  const handleDelete = (item: components['schemas']['WorkflowIndexResource']) => {
    modal.confirm({
      title: t('Confirms.Titles.WorkflowDelete'),
      content: (
        <TranslateHTML>
          {t('Confirms.Contents.DeleteWorkflow{{value}}', {
            value: item.title,
          })}
        </TranslateHTML>
      ),
      onOk: async () => {
        try {
          await mutateAsync(parseInt(item.id));
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.workflows.index],
          });
          message.success(t('Messages.WorkflowDeleted'));
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  const columns: TableProps<components['schemas']['WorkflowIndexResource']>['columns'] = [
    {
      title: t('Workflows.Labels.Title'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: t('Workflows.Labels.Version'),
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: t('Templates.Labels.Description'),
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: t('Templates.Labels.Created'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value: string) => <Date value={value} />,
      className: 'text-nowrap',
    },
    {
      title: t('Templates.Labels.Updated'),
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (value: string) => <Date value={value} />,
      className: 'text-nowrap',
    },
    {
      title: t('Workflows.Labels.Actions'),
      render: (_: any, row: any) =>
        row.readOnly ? null : (
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(row);
            }}
          />
        ),
      align: 'right',
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data}
      rowKey="id"
      onRow={(record) => {
        return {
          onClick: () => onClick(record),
        };
      }}
      pagination={{ ...pagination, showSizeChanger: true }}
      onChange={(pagination) => onPaginationChange(pagination)}
    />
  );
};
