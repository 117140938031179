import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { IdleTimer, PageLoader, TokenRefresher, WebSocketNotifications } from 'src/components';
import { routerPaths } from 'src/consts';
import { useAuth } from 'src/contexts';
import { servicesProfile } from 'src/services';

interface ProtectedRouteProps {
  children: JSX.Element | JSX.Element[];
}

export const RoutesAuth = ({ children }: ProtectedRouteProps) => {
  const { data, isFetching, isRefetching } = servicesProfile.getShow();
  const { isAuthenticated, setAuth } = useAuth();

  useEffect(() => {
    if (data?.data) {
      setAuth(data.data);
    }
  }, [data]);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={routerPaths.login}
        replace={true}
      />
    );
  }

  if (isFetching && !isRefetching) {
    return <PageLoader type="login" />;
  }

  return (
    <>
      <IdleTimer />
      <TokenRefresher />
      <WebSocketNotifications />
      {children}
    </>
  );
};
