import { EditOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Row } from 'antd';
import 'ckeditor5/ckeditor5.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from 'src/consts';

import { CKEditorCustom } from '../../components';
import { TApiTemplateCreateRequest, servicesTemplate } from '../../services';
import { FieldsInput, FieldsSelect, FieldsTextarea, Form } from '../../shared';

const TemplateSchema = z.object({
  title: z.string().trim().min(2).max(255),
  content: z.string(),
  description: z.string().trim().min(2).max(600),
  meta: z.object({
    signature_type: z.enum(['none', 'manual', 'electronic', 'both']),
  }),
});

export const PagesTemplatesCreate = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { mutateAsync } = servicesTemplate.postTemplate();
  const { t } = useTranslation();

  const handleSubmit = async (values: TApiTemplateCreateRequest) => {
    try {
      await mutateAsync(values);
      message.success(t('Messages.TemplateCreated'));
      navigate(routerPaths.templates.index);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        title: '',
        content: '',
        description: '',
        meta: {
          signatureType: 'none',
        },
      }}
      validationSchema={TemplateSchema}
    >
      {({ formState }) => (
        <Row
          gutter={styles.gutter.default}
          className="h-full"
          align="stretch"
          wrap={false}
        >
          <Col flex="auto">
            <Card className="mb-4">
              <FieldsInput
                name="title"
                prefix={<EditOutlined />}
                size="large"
                variant="borderless"
                placeholder={t('TemplatesCreate.Labels.PlaceHolder')}
                className="font-bold"
                wrapperClassName="mb-0"
              />
            </Card>
            <Card>
              <div className="main-container">
                <CKEditorCustom name="content" />
              </div>
            </Card>
          </Col>
          <Col style={{ width: 320, minWidth: 320 }}>
            <Card className="h-full">
              <FieldsSelect
                name="meta.signature_type"
                options={[
                  { label: t('TemplatesCreate.Options.None'), value: 'none' },
                  { label: t('TemplatesCreate.Options.Manual'), value: 'manual' },
                  { label: t('TemplatesCreate.Options.Electronic'), value: 'electronic' },
                  { label: t('TemplatesCreate.Options.Both'), value: 'both' },
                ]}
                label={t('TemplatesCreate.Labels.SignMethod')}
              />
              <FieldsTextarea
                name="description"
                autoSize={{ minRows: 3, maxRows: 3 }}
                label={t('TemplatesCreate.Labels.Description')}
                maxLength={600}
              />
              <Button
                type="primary"
                block={true}
                htmlType="submit"
                loading={formState.isSubmitting}
              >
                {t('TemplatesCreate.Buttons.Submit')}
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </Form>
  );
};
