import { Checkbox, CheckboxProps } from 'antd';
import { useController } from 'react-hook-form';

import { getNestedObject } from '../../../helpers';
import { FieldsError } from '../Error';
import { FieldsLabel } from '../Label';

interface IPros extends CheckboxProps {
  name: string;
  label?: string;
}

export const FieldsCheckbox = ({ name, label, ...restProps }: IPros) => {
  const { field, formState } = useController({
    name: name,
    shouldUnregister: true,
  });

  const error = getNestedObject<{ message: string } | undefined>(formState.errors, name);

  return (
    <div className="mb-4">
      <FieldsLabel label={label} />
      <Checkbox
        ref={field.ref}
        onChange={(e) => field.onChange(e.target.checked)}
        checked={field.value}
        disabled={formState.isSubmitting}
        {...restProps}
      />
      <FieldsError error={error?.message} />
    </div>
  );
};
