import { QueryObserverOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiTemplatesRequestQuery = operations['templates.index']['parameters']['query'];
export type TApiTemplatesResponse = operations['templates.index']['responses']['200']['content']['application/json'];

const useGetTemplates = (query: TApiTemplatesRequestQuery) => {
  return useQuery<AxiosResponse<TApiTemplatesResponse>, AxiosError>({
    queryKey: [queryKeys.templates.index],
    queryFn: () => helpersAxios(apiPaths.templates.getTemplates(qs.stringify(query))),
  });
};

type TApiTemplateRequest = operations['templates.show']['parameters']['path'];
export type TApiTemplateResponse = operations['templates.show']['responses']['200']['content']['application/json']['data'];

const useGetTemplate = (
  { template, version_id }: TApiTemplateRequest,
  options: Omit<QueryObserverOptions<AxiosResponse<TApiTemplateResponse>, AxiosError>, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery<AxiosResponse<TApiTemplateResponse>, AxiosError>({
    queryKey: [queryKeys.templates.show, template, version_id],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.templates.getTemplate(template, version_id));

      return res.data;
    },
    ...options,
  });
};

export type TApiTemplateCreateRequest = operations['templates.store']['requestBody']['content']['application/json'];
type TApiTemplateCreateResponse = operations['templates.store']['responses']['201']['content']['application/json'];

const usePostTemplate = () => {
  return useMutation<AxiosResponse<TApiTemplateCreateResponse>, AxiosError, TApiTemplateCreateRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiTemplateCreateResponse>> => await helpersAxios.post(apiPaths.templates.postTemplates, data),
  });
};

export type TApiTemplateUpdateRequest = {
  data: operations['templates.update']['requestBody']['content']['application/json'];
  id: operations['templates.update']['parameters']['path']['template'];
};
type TApiTemplateUpdateResponse = operations['templates.update']['responses']['200']['content']['application/json'];

const usePutTemplate = () => {
  return useMutation<AxiosResponse<TApiTemplateUpdateResponse>, AxiosError, TApiTemplateUpdateRequest>({
    mutationFn: async ({ id, data }): Promise<AxiosResponse<TApiTemplateUpdateResponse>> => await helpersAxios.put(apiPaths.templates.putTemplates(id), data),
  });
};

type TApiTemplateDeleteRequest = operations['templates.destroy']['parameters']['path']['template'];
type TApiTemplateDeleteResponse = operations['templates.destroy']['responses']['200']['content']['application/json'];

const useDeleteTemplate = () => {
  return useMutation<AxiosResponse<TApiTemplateDeleteResponse>, AxiosError, TApiTemplateDeleteRequest>({
    mutationFn: async (id): Promise<AxiosResponse<TApiTemplateDeleteResponse>> => await helpersAxios.delete(apiPaths.templates.deleteTemplates(id)),
  });
};

export const servicesTemplate = {
  getTemplates: useGetTemplates,
  getTemplate: useGetTemplate,
  postTemplate: usePostTemplate,
  putTemplate: usePutTemplate,
  deleteTemplate: useDeleteTemplate,
};
