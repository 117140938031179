import { Modal } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { routerPaths } from '../../consts';
import { secondsToMinutes } from '../../helpers';

const TIMEOUT = 360_000;
const PROMPT_BEFORE_IDLE = 60_000;

export const IdleTimer = () => {
  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogout = useCallback(() => {
    setOpen(false);
    setRemaining(0);
    navigate(routerPaths.logout);
  }, [navigate]);

  const onPrompt = useCallback(() => {
    setOpen(true);
    setRemaining(PROMPT_BEFORE_IDLE / 1000);
  }, []);

  const onIdle = useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  const { activate } = useIdleTimer({
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    onPrompt,
    onIdle,
    timers: workerTimers,
  });

  const handleStillHere = useCallback(() => {
    setOpen(false);
    activate();
  }, [activate]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const interval = setInterval(() => {
      setRemaining((prevRemaining) => {
        const nextRemaining = prevRemaining - 1;
        document.title = `${import.meta.env.VITE_TITLE} - ${secondsToMinutes(nextRemaining)}`;
        return nextRemaining;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      document.title = import.meta.env.VITE_TITLE;
    };
  }, [open]);

  return (
    <Modal
      okText={t('IdleTimerModal.Buttons.Stay')}
      cancelText={t('IdleTimerModal.Buttons.Logout')}
      closeIcon={false}
      title={t('IdleTimerModal.Title')}
      open={open}
      maskClosable={false}
      onOk={handleStillHere}
      onCancel={handleLogout}
    >
      <div>
        {t('IdleTimerModal.Message')} <strong>{secondsToMinutes(remaining)}</strong>
      </div>
    </Modal>
  );
};
