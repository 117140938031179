export const FlagImage = ({ name }: { name: string }) => {
  if (!name) {
    return null;
  }

  return (
    <img
      src={new URL(window.location.origin + `/assets/icons/flags/${name}.svg`, import.meta.url).href}
      alt={name}
      style={{ width: '24px', height: '18px' }}
    />
  );
};
