import { EditOutlined, SignatureOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';

import { styles } from '../../consts';
import { TagDocumentStatus } from '../TagDocumentStatus';

interface IProps {
  data: any;
  onClick: (id: string) => void;
  className?: string;
}

export const CardDocument = ({ data, onClick, className }: IProps) => {
  return (
    <Card
      className={className}
      onClick={() => onClick(data.id)}
      hoverable={true}
    >
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <Typography.Title level={5}>{data.title}</Typography.Title>
          <Typography.Paragraph type="secondary">{data.description}</Typography.Paragraph>
        </Col>
        <Col flex="none">
          <Space>
            <Button icon={<EditOutlined />}>Edit</Button>
            <Button icon={<SignatureOutlined />}>Sign</Button>
          </Space>
        </Col>
      </Row>
      <Divider className="my-3" />
      <Row gutter={styles.gutter.default}>
        <Col flex="auto">
          <TagDocumentStatus status={data.status} />
        </Col>
        <Col>
          <Typography.Text type="secondary">Updated date: 2024-12-04</Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};
