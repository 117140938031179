import { UserAddOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TableUsers } from 'src/components';
import { routerPaths } from 'src/consts';
import { servicesUsers } from 'src/services';
import { ContentHeader } from 'src/shared';

export const PagesUsers = () => {
  const { t } = useTranslation();
  const { data } = servicesUsers.getUsers();
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    navigate(routerPaths.users.preview.replace(':id', id.toString()));
  };

  return (
    <div>
      <ContentHeader
        title={t('Users.Title')}
        extra={<Button icon={<UserAddOutlined />}>{t('Users.Buttons.AddNew')}</Button>}
      />

      <TableUsers
        data={data}
        onClick={handleClick}
      />
    </div>
  );
};
