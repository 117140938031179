import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

export const BranchSelector = () => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Kita imone 1',
    },
    {
      key: '2',
      label: 'Kita imone 2',
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      placement="bottom"
    >
      <Button
        iconPosition="end"
        type="text"
        icon={<DownOutlined />}
        size="large"
      >
        Matavi MB
      </Button>
    </Dropdown>
  );
};
