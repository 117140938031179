export const PagesEmailTemplates = () => {
  return (
    <div>
      <iframe
        style={{ width: '100%', height: '80vh', border: 'none' }}
        src="/emails/resend-password.html"
      />
    </div>
  );
};
