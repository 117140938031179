export const queryKeys = {
  clients: {
    index: 'clients.index',
    show: 'clients.show',
  },
  profile: {
    show: 'profile.show',
  },
  classifiers: {
    index: 'classifiers.index',
  },
  permissions: {
    index: 'permissions.index',
  },
  workflows: {
    index: 'workflows.index',
    show: 'workflows.show',
  },
  schemas: {
    index: 'schemas.index',
    show: 'schemas.show',
  },
  selections: {
    index: 'selections.index',
    show: 'selections.show',
  },
  templates: {
    index: 'templates.index',
    show: 'templates.show',
  },
  users: {
    index: 'users.index',
  },
};
