import { notification } from 'antd';
import { useEffect } from 'react';

import { usePusher } from 'src/hooks';

interface IMessage {
  title: string;
  updater: {
    name: string;
    surname: string;
  };
}

export const WebSocketNotifications = () => {
  const [api, contextHolder] = notification.useNotification();

  const { data } = usePusher<IMessage>({
    channelName: 'global',
    eventName: 'template.updated',
  });

  useEffect(() => {
    if (data) {
      api.info({
        message: data?.title,
        description: `${data?.updater?.name} ${data?.updater?.surname}`,
      });
    }
  }, [data]);

  return <>{contextHolder}</>;
};
