import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { FieldsInput, Form } from '../../shared';

interface IProps {
  handleClose: () => void;
}

export const FormSelections = ({ handleClose }: IProps) => {
  const { t } = useTranslation();
  const handleSubmit = async (values: any) => {
    console.log(values);
    handleClose();
  };

  return (
    <div>
      <Form
        initialValues={{}}
        onSubmit={handleSubmit}
      >
        {({ formState }) => (
          <>
            <FieldsInput
              name="title"
              label={t('FormSelections.Title')}
            />
            <Button
              loading={formState.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {t('FormSelections.Buttons.Submit')}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};
