import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiClientsRequestQuery = operations['clients.index']['parameters']['query'];
export type TApiClientsResponse = operations['clients.index']['responses']['200']['content']['application/json'];

const useGetClients = (query: TApiClientsRequestQuery) => {
  return useQuery<AxiosResponse<TApiClientsResponse>, AxiosError>({
    queryKey: [queryKeys.clients.index],
    queryFn: () => helpersAxios(apiPaths.clients.getClients(qs.stringify(query))),
  });
};

// @ts-ignore
export type TApiClientCreateRequest = operations['clients.store']['requestBody']['content']['application/json'];
type TApiClientCreateResponse = operations['clients.store']['responses']['200']['content']['application/json'];

const usePostClient = () => {
  return useMutation<AxiosResponse<TApiClientCreateResponse>, AxiosError, TApiClientCreateRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiClientCreateResponse>> => await helpersAxios.post(apiPaths.clients.postClient, data),
  });
};

export type TApiClientRequest = operations['clients.show']['parameters']['path']['client'];
export type TApiClientResponse = operations['clients.show']['responses']['200']['content']['application/json'];

const useGetClient = (clientId: TApiClientRequest) => {
  return useQuery<AxiosResponse<TApiClientResponse>, AxiosError>({
    queryKey: [queryKeys.clients.show, clientId],
    queryFn: async () => await helpersAxios(apiPaths.clients.getClient(clientId)),
  });
};

export type TApiClientUpdateRequest = {
  data: any; //operations['clients.update']['requestBody']['content']['application/json'];
  id: operations['clients.update']['parameters']['path']['client'];
};

type TApiClientUpdateResponse = operations['clients.update']['responses']['200']['content']['application/json'];

const usePutClient = () => {
  return useMutation<AxiosResponse<TApiClientUpdateResponse>, AxiosError, TApiClientUpdateRequest>({
    mutationFn: async ({ id, data }): Promise<AxiosResponse<TApiClientUpdateResponse>> => await helpersAxios.put(apiPaths.clients.putClient(id), data),
  });
};

type TApiClientDeleteRequest = operations['clients.destroy']['parameters']['path']['client'];
type TApiClientDeleteResponse = operations['clients.destroy']['responses']['200']['content']['application/json'];

const useDeleteClient = () => {
  return useMutation<AxiosResponse<TApiClientDeleteResponse>, AxiosError, TApiClientDeleteRequest>({
    mutationFn: async (id): Promise<AxiosResponse<TApiClientDeleteResponse>> => await helpersAxios.delete(apiPaths.clients.deleteClients(id)),
  });
};

export const servicesClients = {
  getClients: useGetClients,
  postClient: usePostClient,
  getClient: useGetClient,
  putClient: usePutClient,
  deleteClient: useDeleteClient,
};
