import {
  BorderOutlined,
  CloseOutlined,
  DownOutlined,
  DownloadOutlined,
  ExpandOutlined,
  FullscreenOutlined,
  LeftOutlined,
  PrinterOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SaveOutlined,
  SignatureOutlined,
  SplitCellsOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Affix, Alert, Button, Dropdown, MenuProps, Skeleton, Space, Tooltip, Typography } from 'antd';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

interface PDFViewerProps {
  url: string;
  documentName: string;
  styleWrapper?: CSSProperties;
  disableDownload?: boolean;
  disablePrint?: boolean;
  disableRotation?: boolean;
  singlePage?: boolean;
}

interface ISignature {
  page: number;
  data: string;
}

export const PDFViewer = ({ url, styleWrapper, documentName, disableDownload, disablePrint, singlePage, disableRotation }: PDFViewerProps) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [isMultiPage, setIsMultiPage] = useState(!singlePage);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });
  const [isSignatureMode, setIsSignatureMode] = useState(false);
  const [signatures, setSignatures] = useState<ISignature[]>([]);

  const viewerRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const sigCanvas = useRef<SignatureCanvas>(null);

  const handleFit = useCallback(() => {
    if (viewerRef.current && pdfDimensions.width > 0) {
      const pdfContainer = viewerRef.current.querySelector('.pdf-document') as HTMLElement;

      if (pdfContainer) {
        const newScale = Math.max(pdfContainer.clientWidth / pdfDimensions.width, 0.5);
        setScale(newScale);
      }
    }
  }, [pdfDimensions.width]);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const pdf = await pdfjs.getDocument(url).promise;
        setNumPages(pdf.numPages);
        setLoading(false);
        setPageNumber(1);
        setIsMultiPage(!singlePage && pdf.numPages <= 20);
        pageRefs.current = new Array(pdf.numPages).fill(null);

        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        setPdfDimensions({ width: viewport.width, height: viewport.height });

        setTimeout(handleFit, 100);
      } catch (err) {
        console.error('Error loading PDF:', err);
        setError('Failed to load PDF. Please try again.');
        setLoading(false);
      }
    };

    loadPDF();
  }, [url, handleFit, singlePage]);

  useEffect(() => {
    if (numPages > 0 && pdfDimensions.width > 0) {
      handleFit();
    }
  }, [numPages, pdfDimensions.width, handleFit]);

  const isMobile = useCallback(() => /Mobi|Android/i.test(navigator.userAgent), []);

  const handlePrint = useCallback(() => {
    if (isMobile()) {
      downloadPDF();
      return;
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();
      }, 1);
    };

    iframe.onerror = () => {
      console.error('Failed to load PDF for printing');
      document.body.removeChild(iframe);
    };
  }, [url, isMobile]);

  const downloadPDF = useCallback(() => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${documentName}.pdf`;
    link.click();
  }, [url, documentName]);

  const changePage = useCallback(
    (offset: number) => {
      setPageNumber((prevPageNumber) => {
        const newPageNumber = Math.min(Math.max(prevPageNumber + offset, 1), numPages);
        scrollToPage(newPageNumber);
        return newPageNumber;
      });
    },
    [numPages],
  );

  const calculateVisiblePage = useCallback(() => {
    if (!containerRef.current) return;

    const containerTop = containerRef.current.scrollTop;
    const containerBottom = containerTop + containerRef.current.clientHeight;
    let maxVisibleHeight = 0;
    let mostVisiblePage = 1;

    pageRefs.current.forEach((pageRef, index) => {
      if (pageRef) {
        const rect = pageRef.getBoundingClientRect();
        const pageTop = rect.top - containerRef.current!.offsetTop + containerTop;
        const pageBottom = pageTop + rect.height;

        const visibleTop = Math.max(containerTop, pageTop);
        const visibleBottom = Math.min(containerBottom, pageBottom);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);

        if (visibleHeight > maxVisibleHeight) {
          maxVisibleHeight = visibleHeight;
          mostVisiblePage = index + 1;
        }
      }
    });

    setPageNumber(mostVisiblePage);
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', calculateVisiblePage);
    return () => container?.removeEventListener('scroll', calculateVisiblePage);
  }, [numPages, calculateVisiblePage]);

  const scrollToPage = useCallback((pageNum: number) => {
    const targetPage = pageRefs.current[pageNum - 1];
    if (targetPage && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const targetRect = targetPage.getBoundingClientRect();

      containerRef.current.scrollTo({
        top: targetRect.top - containerRect.top + containerRef.current.scrollTop,
        behavior: 'smooth',
      });
    }
  }, []);

  const zoomIn = useCallback(() => setScale((prevScale) => Math.min(prevScale + 0.25, 10)), []);
  const zoomOut = useCallback(() => setScale((prevScale) => Math.max(prevScale - 0.25, 0.1)), []);

  const rotateClockwise = useCallback(() => setRotation((prevRotation) => (prevRotation + 90) % 360), []);
  const rotateCounterClockwise = useCallback(() => setRotation((prevRotation) => (prevRotation - 90 + 360) % 360), []);

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      viewerRef.current?.requestFullscreen().then(() => setIsFullscreen(true));
    } else {
      document.exitFullscreen().then(() => setIsFullscreen(false));
    }
  }, []);

  useEffect(() => {
    const handleExitFullscreen = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false);
      }
      handleFit();
    };

    document.addEventListener('fullscreenchange', handleExitFullscreen);
    return () => document.removeEventListener('fullscreenchange', handleExitFullscreen);
  }, [handleFit]);

  const renderPage = useCallback(
    (pageNum: number) => (
      <div
        key={`page_${pageNum}`}
        className="pdf-page"
        ref={(el) => (pageRefs.current[pageNum - 1] = el)}
        style={{ marginBottom: isMultiPage ? 16 : undefined, textAlign: 'center' }}
      >
        <Page
          pageNumber={pageNum}
          scale={scale}
          rotate={rotation}
          onRenderError={(error: Error) => {
            console.error('Error rendering page:', error);
            setError('Failed to render page. Please try again.');
          }}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          className="pdf-viewer-page"
        />
        {signatures.find((signature) => signature.page === pageNum) && (
          <div style={{ rotate: `${rotation}deg`, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
            <img
              src={signatures.find((signature) => signature.page === pageNum)!.data}
              alt="Signature"
              style={{
                width: pdfDimensions.width * scale,
                height: pdfDimensions.height * scale,
              }}
            />
          </div>
        )}
        {isSignatureMode && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', bottom: 0, zIndex: 2, overflow: 'hidden' }}>
            <SignatureCanvas
              ref={sigCanvas}
              penColor="#3a365b"
              canvasProps={{
                className: 'sigCanvas',
                width: pdfDimensions.width * scale,
                height: pdfDimensions.height * scale,
              }}
            />
          </div>
        )}
      </div>
    ),
    [isMultiPage, scale, rotation, signatures, pdfDimensions.width, pdfDimensions.height, isSignatureMode],
  );

  const handleSaveSignature = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const signatureData = sigCanvas.current.toDataURL();
      setSignatures((prevState) => [...prevState, { page: pageNumber, data: signatureData }]);
      setIsSignatureMode(false);
    } else {
      setIsSignatureMode(false);
    }
  };

  if (error) {
    return <Typography.Text type="danger">{error}</Typography.Text>;
  }

  if (loading) {
    return <Skeleton active={true} />;
  }

  const items: MenuProps['items'] = [
    ...signatures.map((signature) => ({
      key: `remove-${signature.page}`,
      label: `Remove from page ${signature.page}`,
      onClick: () => setSignatures((prevState) => prevState.filter((item) => item.page !== signature.page)),
    })),
    {
      type: 'divider',
    },
    {
      key: 'remove-all',
      danger: true,
      label: 'Remove all signatures',
      onClick: () => setSignatures([]),
    },
  ];

  return (
    <div
      ref={viewerRef}
      style={{
        ...styleWrapper,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
        border: '1px solid #f0f0f0',
      }}
    >
      <Affix offsetTop={0}>
        <div
          style={{
            padding: '16px',
            backgroundColor: '#ffffff',
            display: 'flex',
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            zIndex: 2,
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <Tooltip title={t('PDFViewer.PreviousPage')}>
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={() => changePage(-1)}
              disabled={pageNumber <= 1}
            />
          </Tooltip>
          <Space>
            <Typography.Text strong={true}>
              {pageNumber} / {numPages}
            </Typography.Text>
          </Space>
          <Tooltip title={t('PDFViewer.NextPage')}>
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => changePage(1)}
              disabled={pageNumber >= numPages}
            />
          </Tooltip>
          <Tooltip title={t('PDFViewer.ZoomIn')}>
            <Button
              type="text"
              icon={<ZoomInOutlined />}
              onClick={zoomIn}
            />
          </Tooltip>
          <Tooltip title={t('PDFViewer.Fit')}>
            <Button
              type="text"
              onClick={handleFit}
              icon={<FullscreenOutlined />}
            />
          </Tooltip>
          <Tooltip title={t('PDFViewer.ZoomOut')}>
            <Button
              type="text"
              icon={<ZoomOutOutlined />}
              onClick={zoomOut}
            />
          </Tooltip>
          {!disableRotation && (
            <>
              <Tooltip title={t('PDFViewer.RotateClockwise')}>
                <Button
                  type="text"
                  icon={<RotateRightOutlined />}
                  onClick={rotateClockwise}
                />
              </Tooltip>
              <Tooltip title={t('PDFViewer.RotateCounterClockwise')}>
                <Button
                  type="text"
                  icon={<RotateLeftOutlined />}
                  onClick={rotateCounterClockwise}
                />
              </Tooltip>
            </>
          )}
          {!disableDownload && (
            <Tooltip title={t('PDFViewer.Download')}>
              <Button
                type="text"
                icon={<DownloadOutlined />}
                onClick={downloadPDF}
              />
            </Tooltip>
          )}
          {!disablePrint && (
            <Tooltip title={t('PDFViewer.Print')}>
              <Button
                type="text"
                icon={<PrinterOutlined />}
                onClick={handlePrint}
              />
            </Tooltip>
          )}
          <Tooltip title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}>
            <Button
              type={isFullscreen ? 'primary' : 'text'}
              icon={<ExpandOutlined />}
              onClick={toggleFullscreen}
            />
          </Tooltip>
          {!singlePage && (
            <Tooltip title={isMultiPage ? 'Single Page' : 'Multiple Page'}>
              <Button
                type="text"
                onClick={() => setIsMultiPage((prevState) => !prevState)}
                icon={isMultiPage ? <BorderOutlined /> : <SplitCellsOutlined style={{ transform: 'rotate(90deg)' }} />}
              />
            </Tooltip>
          )}
          <Space style={{ marginLeft: 'auto' }}>
            {isSignatureMode ? (
              <>
                <Button
                  type="primary"
                  onClick={handleSaveSignature}
                  icon={<SaveOutlined />}
                >
                  Save signature
                </Button>
                <Button
                  onClick={() => setIsSignatureMode(false)}
                  icon={<CloseOutlined />}
                >
                  Cancel
                </Button>
              </>
            ) : signatures.find((signature) => signature.page === pageNumber) ? (
              <Dropdown menu={{ items }}>
                <Button
                  type="text"
                  icon={<DownOutlined />}
                  iconPosition="end"
                >
                  Remove signatures
                </Button>
              </Dropdown>
            ) : (
              <Button
                type="text"
                onClick={() => setIsSignatureMode((prevState) => !prevState)}
                icon={<SignatureOutlined />}
              >
                Signature mode
              </Button>
            )}
          </Space>
        </div>
      </Affix>
      {isSignatureMode && (
        <Alert
          icon={<SignatureOutlined />}
          type="info"
          message="Now you can sign everywhere, set your signature and save it. You can also remove it."
          banner={true}
        />
      )}
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          width: '100%',
          position: 'relative',
        }}
        ref={containerRef}
        onDoubleClick={isSignatureMode ? undefined : zoomIn}
      >
        <Document
          file={url}
          onLoadSuccess={() => setTimeout(handleFit, 100)}
          onLoadError={(error: Error) => {
            console.error('Error loading PDF:', error);
            setError('Failed to load PDF. Please try again.');
          }}
          className="pdf-document"
        >
          <div>{isMultiPage ? Array.from(new Array(numPages), (_, index) => renderPage(index + 1)) : renderPage(pageNumber)}</div>
        </Document>
      </div>
    </div>
  );
};
