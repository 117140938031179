import { Button, Flex, Skeleton } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { CardWorkflow, FormClientBusinessEdit, FormClientPrivateEdit } from '../../components';
import { routerPaths } from '../../consts';
import { servicesClients } from '../../services';

export const PagesClientsPreview = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data, isFetching } = servicesClients.getClient(parseInt(id) ?? 0);

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <div>
      <Button onClick={() => navigate(routerPaths.clients.index)}>Back</Button>
      {data?.data?.data.type === 'private' && <FormClientPrivateEdit data={data?.data?.data} />}
      {data?.data?.data.type === 'business' && <FormClientBusinessEdit data={data?.data?.data} />}
      <h2>Workflows</h2>
      <div className="mb-3">
        <CardWorkflow
          data={{
            id: '123412314',
            title: 'Title',
            author: 'Antanas Petraitis',
            description: 'Description',
          }}
          onClick={(id) => navigate(routerPaths.workflows.preview.replace(':id', id))}
        />
      </div>
      <Flex justify="end">
        <Button>Add workflow</Button>
      </Flex>
    </div>
  );
};
