import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty, Table, TableProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { components } from '../../interfaces';
import { ModalClassifier } from '../ModalClassifier';

interface IProps {
  data: components['schemas']['ClassifierResource'][];
}

export const CollapseClassifiers = ({ data }: IProps) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  const handleDelete = (item: components['schemas']['ClassifierResource']) => {
    console.log('handleDelete ', item);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [modalClassifier, setModalClassifier] = useState<{
    entity: string | null;
    group: string | null;
    parent_id: number | null;
    name: string | null;
    type: string | null;
    slug?: string | null;
    selection: string | null;
  }>({
    entity: null,
    group: null,
    parent_id: null,
    name: null,
    type: 'text',
    slug: null,
    selection: null,
  });

  const columns: TableProps<components['schemas']['ClassifierResource']>['columns'] = [
    {
      title: t('Classifiers.Labels.Label'),
      dataIndex: 'name',
    },
    {
      title: t('Classifiers.Labels.Value'),
      dataIndex: 'slug',
    },
    {
      title: t('Classifiers.Labels.Type'),
      dataIndex: 'type',
    },
    {
      title: t('Classifiers.Labels.TypeOptions'),
      dataIndex: 'typeOptions',
    },
    {
      title: t('Classifiers.Labels.Actions'),
      render: (_: any, row: any) =>
        row.readOnly ? null : (
          <Button.Group>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                setIsOpen(true);
                setModalClassifier({
                  parent_id: null,
                  entity: null,
                  group: null,
                  name: null,
                  type: null,
                  selection: null,
                });
                setModalClassifier(row);
              }}
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => handleDelete(row)}
            />
          </Button.Group>
        ),
      align: 'right',
    },
  ];

  return (
    <Collapse className="mb-4">
      {data.map((entity) => (
        <Panel
          header={entity.name}
          key={entity.slug}
          extra={
            <Button
              onClick={(e) => {
                setIsOpen(true);
                setModalClassifier({
                  parent_id: entity.id,
                  entity: entity.slug,
                  group: null,
                  name: null,
                  type: null,
                  selection: null,
                });
                e.stopPropagation();
              }}
              type="link"
              size="small"
              icon={<PlusOutlined />}
            >
              {t('Classifiers.Buttons.AddEntityGroup')}
            </Button>
          }
        >
          {entity.children && entity.children.length > 0 ? (
            <Collapse destroyInactivePanel={true}>
              {entity.children.map((group) => (
                <Panel
                  header={group.name}
                  key={group.slug}
                  extra={
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalClassifier({
                          parent_id: entity.id,
                          entity: entity.slug,
                          group: group.slug,
                          name: '',
                          type: 'text',
                          selection: null,
                        });
                      }}
                      type="link"
                      size="small"
                      icon={<PlusOutlined />}
                    >
                      {t('Classifiers.Buttons.AddClassifier')}
                    </Button>
                  }
                >
                  <Table
                    rowKey="slug"
                    tableLayout="fixed"
                    size="small"
                    bordered={true}
                    columns={columns}
                    dataSource={group.children?.map((item) => ({
                      ...item,
                      entity: entity.slug,
                      entityName: entity.name,
                      group: group.slug,
                      groupName: group.name,
                      children: undefined,
                    }))}
                    pagination={false}
                  />
                </Panel>
              ))}
            </Collapse>
          ) : (
            <Empty />
          )}
        </Panel>
      ))}
      <ModalClassifier
        open={isOpen}
        data={modalClassifier}
        handleClose={() => {
          setIsOpen(false);
          setModalClassifier({
            parent_id: null,
            entity: null,
            group: null,
            name: null,
            type: 'text',
            selection: null,
          });
        }}
      />
    </Collapse>
  );
};
