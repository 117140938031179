import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Progress, Row, Upload, UploadProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import fileDownload from '../../assets/images/file-download.png';
import { styles } from '../../consts';
import { helpersAxiosFiles } from '../../helpers';
import { FileSizeDisplay } from '../FileSizeDisplay';

interface IProps {
  multiple?: boolean;
}

interface FileUploadState {
  uid: string;
  name: string;
  size?: number;
  progress?: { total?: number; loaded: number };
  abortController?: AbortController;
}

export const FileUpload = ({ multiple = false }: IProps) => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileUploadState[]>([]);

  const updateFileState = (uid: string, updates: Partial<FileUploadState>) => {
    setFiles((prevFiles) => prevFiles.map((file) => (file.uid === uid ? { ...file, ...updates } : file)));
  };

  const removeFileState = (uid: string) => {
    setFiles((prevFiles) => prevFiles.filter((e) => e.uid !== uid));
  };

  const customUploadHandler = async (options: any) => {
    const { file, onSuccess, onError } = options;

    setFiles((prevState) => [...prevState, { ...file, uid: file.uid }]);
    const controller = new AbortController();
    updateFileState(file.uid, { abortController: controller });

    try {
      const formData = new FormData();
      formData.append('file', file as File);

      const response = await helpersAxiosFiles.post('/upload', formData, {
        signal: controller.signal,
        onUploadProgress: (progressEvent) => {
          updateFileState(file.uid, {
            progress: {
              loaded: progressEvent.loaded,
              total: progressEvent.total,
            },
          });
        },
      });

      console.log(file.uid);
      message.success(`${file.name} uploaded successfully`);
      console.log(file);
      removeFileState(file.uid);
      onSuccess?.(response.data, file);
    } catch (error) {
      if (controller.signal.aborted) {
        message.warning(`${file.name} upload canceled`);
      } else {
        message.error(`${file.name} upload failed`);
      }
      removeFileState(file.uid);
      onError?.(error as Error);
    }
  };

  const cancelUpload = (uid: string) => {
    const file = files.find((f) => f.uid === uid);
    file?.abortController?.abort();
    removeFileState(uid);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: multiple,
    customRequest: customUploadHandler,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    itemRender: () => null,
  };

  return (
    <>
      <div className="mb-3">
        <Upload.Dragger
          {...props}
          className="ant-upload-override"
        >
          <div>
            <img
              src={fileDownload}
              alt="upload"
            />
          </div>
          <div>{t('FileUpload.Labels.Upload')}</div>
          <div className="mb-3 text-muted text-xs">
            {t('FileUpload.Labels.Hint{{value}}', {
              defaultValue: '',
              value: 20,
            })}
          </div>
        </Upload.Dragger>
      </div>
      {files.map((file) => (
        <Card
          key={file.uid}
          className="mb-2"
        >
          <Row gutter={styles.gutter.default}>
            <Col flex="none">
              <FileOutlined />
            </Col>
            <Col flex="auto">
              <div className="font-semibold">{file.name}</div>
              <FileSizeDisplay
                className="text-muted"
                bytes={file.progress?.total}
              />
            </Col>
            <Col flex="none">
              <Button
                icon={<CloseOutlined />}
                type="text"
                onClick={() => cancelUpload(file.uid)}
              />
            </Col>
          </Row>
          <Progress percent={Math.round(100 / ((file.progress?.total ?? 0) / (file.progress?.loaded ?? 0)))} />
        </Card>
      ))}
    </>
  );
};
