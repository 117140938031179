import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseClassifiers, ModalClassifier } from 'src/components';
import { servicesClassifiers } from 'src/services';
import { ContentHeader } from 'src/shared';

export const PagesClassifiers = () => {
  const { data, isFetching } = servicesClassifiers.getClassifiers();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [modalClassifier, setModalClassifier] = useState<{
    entity: string | null;
    group: string | null;
    parent_id: number | null;
    name: string | null;
    type: string | null;
    slug?: string | null;
    selection: string | null;
  }>({
    entity: null,
    group: null,
    parent_id: null,
    name: null,
    type: 'text',
    slug: null,
    selection: null,
  });

  return (
    <div>
      <ContentHeader
        title={t('Classifiers.Title')}
        extra={
          <Button
            onClick={() => {
              setIsOpen(true);
              setModalClassifier({
                parent_id: null,
                entity: null,
                group: null,
                name: null,
                type: 'text',
                selection: null,
              });
            }}
            icon={<PlusOutlined />}
          >
            {t('Classifiers.Buttons.AddEntity')}
          </Button>
        }
      />
      {isFetching ? <Skeleton /> : data?.data && data?.data?.length > 0 ? <CollapseClassifiers data={data.data} /> : <Empty />}
      <ModalClassifier
        open={isOpen}
        data={modalClassifier}
        handleClose={() => {
          setIsOpen(false);
          setModalClassifier({
            parent_id: null,
            entity: null,
            group: null,
            name: null,
            type: 'text',
            selection: null,
          });
        }}
      />
    </div>
  );
};
