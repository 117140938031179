import { App, Button, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from '../../consts';
import { EnumSelectionTypes } from '../../enums';
import { useSelections } from '../../hooks';
import { components } from '../../interfaces';
import { servicesClients } from '../../services';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from '../../shared';

export const FormClientPrivate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { mutateAsync } = servicesClients.postClient();
  const { getSelections, getSelectionsLoading } = useSelections();

  const handleSubmit = async (data: components['schemas']['StoreClientRequest']) => {
    try {
      await mutateAsync(data);
      message.success(t('Messages.ClientCreated'));
      navigate(routerPaths.clients.index);
    } catch (e) {
      console.log(e);
    }
  };

  const validationSchema = z.object({
    type: z.string().trim().min(1),
    privateClient: z.object({
      name: z.string().trim().min(1),
      surname: z.string().trim().min(1),
      dateOfBirth: z.string().trim().min(1),
      citizenship: z.string().trim().min(1),
      gender: z.string().trim().min(1),
      personalCode: z.string().trim().min(1),
    }),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={{
        type: 'private',
      }}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('FormClientPrivate.Titles.Basic')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('FormClientPrivate.Labels.Name')}
            name="privateClient.name"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('FormClientPrivate.Labels.Surname')}
            name="privateClient.surname"
          />
        </Col>
        <Col span={12}>
          <FieldsDate
            label={t('FormClientPrivate.Labels.Birthday')}
            name="privateClient.dateOfBirth"
          />
        </Col>
        <Col span={12}>
          <FieldsInput
            label={t('FormClientPrivate.Labels.PersonalCode')}
            name="privateClient.personalCode"
          />
        </Col>
        <Col span={12}>
          <FieldsSelect
            label={t('FormClientPrivate.Labels.Gender')}
            name="privateClient.gender"
            options={getSelections(EnumSelectionTypes.genders)}
            loading={getSelectionsLoading.includes(EnumSelectionTypes.genders)}
          />
        </Col>
        <Col span={24}>
          <FieldsSelect
            label={t('FormClientPrivate.Labels.Citizenship')}
            name="privateClient.citizenship"
            options={getSelections(EnumSelectionTypes.countries)}
            loading={getSelectionsLoading.includes(EnumSelectionTypes.countries)}
          />
        </Col>
      </Row>
      <Flex justify="end">
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('FormClientPrivate.Buttons.Submit')}
        </Button>
      </Flex>
    </Form>
  );
};
