import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useMemo, useState } from 'react';

import { apiPaths, queryKeys } from '../consts';
import { EnumSelectionTypes } from '../enums';
import { helpersAxios } from '../helpers';

interface Selection {
  value: string;
  label: string;
}

const fetchSelections = async (language: string, slug: EnumSelectionTypes): Promise<Selection[]> => {
  try {
    const response = await helpersAxios.get(apiPaths.selections.getSelection(slug), {
      params: { language, slug },
    });

    return response.data.data.options;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      console.error(`Error fetching selections: ${axiosError.message}`);
      throw new Error(`Failed to fetch selections: ${axiosError.message}`);
    }

    throw error;
  }
};

export const useSelections = (language: string = 'lt') => {
  const queryClient = useQueryClient();
  const [loadingSelections, setLoadingSelections] = useState<string[]>([]);

  const getSelections = useMemo(() => {
    return (slug: EnumSelectionTypes): Selection[] => {
      const cacheKey = ['selections', language, slug];
      const cachedData = queryClient.getQueryData<Selection[]>(cacheKey);

      if (cachedData) {
        return cachedData;
      }

      if (!loadingSelections.includes(slug)) {
        setLoadingSelections((prev) => [...prev, slug]);
        queryClient
          .prefetchQuery({
            queryKey: cacheKey,
            queryFn: () => fetchSelections(language, slug),
          })
          .then(() => {
            setLoadingSelections((prev) => prev.filter((item) => item !== slug));
          });
      }

      return [];
    };
  }, [queryClient, language, loadingSelections]);

  const useSelectionsQuery = (slug: EnumSelectionTypes): UseQueryResult<Selection[], Error> => {
    return useQuery({
      queryKey: [queryKeys.selections.show, language, slug],
      queryFn: () => fetchSelections(language, slug),
      enabled: false,
    });
  };

  return {
    getSelections,
    useSelectionsQuery,
    getSelectionsLoading: loadingSelections,
  };
};
