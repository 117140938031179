import { useTranslation } from 'react-i18next';

import { CardFile } from '../../components';
import { ContentHeader, FileUpload } from '../../shared';
import { PagesDocumentsPreview } from '../DocumentsPreview';

export const PagesDashboard = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ContentHeader title={t('Dashboard.Title')} />
      <FileUpload />
      <CardFile
        id={'dawc42q3-dfq3vc453ca-arq23c43qwa-qrqa'}
        name={'files.png'}
        size={125465838328}
        author={'Darius Petrauskas'}
        date={'2121-21-21'}
        onDownload={(id) => console.log('download', id)}
        onDelete={(id) => console.log('delete', id)}
      />
      <PagesDocumentsPreview />
    </div>
  );
};
