import logoSmall from '../../assets/logo-flu-small.png';
import logo from '../../assets/logo-flu.png';

interface IProps {
  collapsed: boolean;
}

export const LayoutLogo = ({ collapsed }: IProps) => {
  return (
    <div style={{ marginBottom: 16, textAlign: 'center' }}>
      <img
        style={{ maxWidth: '100%', maxHeight: 32 }}
        src={collapsed ? logoSmall : logo}
        alt={import.meta.env.VITE_TITLE}
        title={import.meta.env.VITE_TITLE}
      />
    </div>
  );
};
