import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import { TApiClassifierCreateRequest, servicesClassifiers } from '../../services';
import { FieldsInput, FieldsSelect, Form } from '../../shared';

interface IProps {
  data: any;
  handleClose: () => void;
}

export const FormClassifier = ({ data, handleClose }: IProps) => {
  const { t } = useTranslation();
  const { mutateAsync } = servicesClassifiers.postClassifier();

  const handleSubmit = async (values: TApiClassifierCreateRequest) => {
    await mutateAsync(values);
    if (values.parentId) {
      console.log('put ', values);
    } else {
      console.log('post ', values);
    }

    handleClose();
  };

  return (
    <div>
      <Form
        initialValues={data}
        onSubmit={handleSubmit}
      >
        {({ watch, formState }) => (
          <>
            <FieldsSelect
              name="type"
              label={t('FormClassifiers.Labels.Type')}
              options={[
                {
                  value: 'text',
                  label: 'Text',
                },
                {
                  value: 'select',
                  label: 'Select',
                },
              ]}
            />
            {watch().type === 'select' && (
              <FieldsSelect
                name="selection"
                label={t('FormClassifiers.Labels.Selection')}
                options={[
                  {
                    value: 'countries',
                    label: 'Countries',
                  },
                ]}
              />
            )}
            <FieldsInput
              name="name"
              label={t('FormClassifiers.Labels.Name')}
            />
            <FieldsInput
              name="group"
              label={t('FormClassifiers.Labels.Group')}
            />
            <Flex justify="end">
              <Button.Group>
                <Button
                  loading={formState.isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  {t('FormClassifiers.Buttons.Submit')}
                </Button>
              </Button.Group>
            </Flex>
            <pre>{JSON.stringify(watch(), undefined, 2)}</pre>
          </>
        )}
      </Form>
    </div>
  );
};
