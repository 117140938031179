import { PDFViewer } from '../../shared';

export const PagesDocumentsPreview = () => {
  return (
    <div>
      <PDFViewer
        url="/tmp/doc_long.pdf"
        documentName="Contract"
        singlePage={true}
        disableRotation={true}
      />
    </div>
  );
};
