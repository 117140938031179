import dayjs from 'dayjs';

interface IProps {
  value?: string | null;
  className?: string;
  format?: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DD HH:mm:ss';
}

export const Date = ({ value, format = 'YYYY-MM-DD HH:mm:ss', className }: IProps) => {
  if (!value || !dayjs(value).isValid) {
    return null;
  }

  return <span className={className}>{dayjs(value).format(format)}</span>;
};
