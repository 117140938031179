import { App, Button, Card, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { routerPaths, styles } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { servicesSchemas } from '../../services';
import { FieldsCheckbox, FieldsInput, FieldsSelect, FieldsTextarea, Form } from '../../shared';

export const FormSchemaCreate = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync } = servicesSchemas.postSchema();

  const handleSubmit = async (values: any) => {
    try {
      await mutateAsync(values);
      message.success(t('Messages.SchemaCreated'));
      navigate(routerPaths.schemas.index);
    } catch (e) {
      console.log(e);
    }
  };

  const validationSchema = z.object({
    title: z.string().trim().min(1),
    description: z.string().trim().min(1),
    clientType: z.string().trim().min(1),
  });

  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{}}
    >
      <div className="mb-3">
        <Card className="mb-3">
          <Row gutter={styles.gutter.default}>
            <Col span={8}>
              <FieldsSelect
                options={[
                  {
                    label: t('Enums.ClientTypes.ALL'),
                    value: EnumClientTypes.All,
                  },
                  {
                    label: t('Enums.ClientTypes.PCR'),
                    value: EnumClientTypes.Private,
                  },
                  {
                    label: t('Enums.ClientTypes.BCR'),
                    value: EnumClientTypes.Business,
                  },
                ]}
                label={t('SchemasCreate.Labels.Type')}
                name="clientType"
              />
            </Col>
            <Col span={8}>
              <FieldsInput
                label={t('SchemasCreate.Labels.Title')}
                name="title"
              />
            </Col>
            <Col span={8}>
              <FieldsInput
                label={t('SchemasCreate.Labels.Description')}
                name="description"
              />
            </Col>
          </Row>
        </Card>
        <Flex
          justify="space-between"
          className="mb-3"
        >
          <Typography.Title level={5}>{t('SchemasCreate.SubTitles.Templates')}</Typography.Title>
          <Button>{t('SchemasCreate.Buttons.Add')}</Button>
        </Flex>
        <Card className="mb-3">
          <Row gutter={styles.gutter.default}>
            <Col span={12}>
              <Row gutter={styles.gutter.default}>
                <Col span={16}>
                  <FieldsSelect
                    label={t('SchemasCreate.Labels.Template')}
                    name="templates"
                  />
                </Col>
                <Col span={8}>
                  <FieldsSelect
                    label={t('SchemasCreate.Labels.TemplateVersion')}
                    name="templateVersion"
                    disabled={true}
                  />
                </Col>
                <Col span={24}>
                  <FieldsTextarea
                    label={t('SchemasCreate.Labels.TemplateDescription')}
                    name="templateDescription"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <FieldsCheckbox name="isSignable">{t('SchemasCreate.Labels.Sign')}</FieldsCheckbox>
              <FieldsSelect
                label={t('SchemasCreate.Labels.SignType')}
                name="signType"
              />
              <FieldsCheckbox name="isMandatory">{t('SchemasCreate.Labels.Mandatory')}</FieldsCheckbox>
              <FieldsCheckbox name="isAllowFill">{t('SchemasCreate.Labels.AllowClientFill')}</FieldsCheckbox>
            </Col>
          </Row>
          <Flex justify="end">
            <Button>{t('SchemasCreate.Buttons.Remove')}</Button>
          </Flex>
        </Card>
        <Flex
          justify="space-between"
          className="mb-3"
        >
          <Typography.Title level={5}>{t('SchemasCreate.SubTitles.Files')}</Typography.Title>
          <Button>Add</Button>
        </Flex>
        <Card className="mb-3">
          <Row gutter={styles.gutter.default}>
            <Col span={12}>
              <FieldsSelect
                label={t('SchemasCreate.Labels.File')}
                name="file"
              />
              <FieldsSelect
                label={t('SchemasCreate.Labels.FileExtensions')}
                name="fileExtensions"
              />
              <FieldsTextarea
                label={t('SchemasCreate.Labels.FileDescription')}
                name="fileDescription"
              />
            </Col>
            <Col span={12}>
              <FieldsCheckbox name="isMandatory">{t('SchemasCreate.Labels.Mandatory')}</FieldsCheckbox>
              <FieldsCheckbox name="isAllowUpload">{t('SchemasCreate.Labels.AllowClientUpload')}</FieldsCheckbox>
            </Col>
          </Row>
          <Flex justify="end">
            <Button>{t('SchemasCreate.Buttons.Remove')}</Button>
          </Flex>
        </Card>
      </div>
      <Flex justify="end">
        <Button
          type="primary"
          htmlType="submit"
        >
          {t('SchemasCreate.Buttons.Submit')}
        </Button>
      </Flex>
    </Form>
  );
};
