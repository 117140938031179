import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { styles } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { FieldsInput, FieldsSelect, Form } from '../../shared';
import { IFormTemplateSearch } from '../FormTemplateSearch/interface.ts';
import { IFormWorkflowSearch, initialFormWorkflowsSearchValues } from './interface';

interface IProps {
  setQuery: Dispatch<SetStateAction<IFormTemplateSearch>>;
}

const formValidationSchema = z.object({
  type: z.string().optional(),
  searchTerm: z.string().optional(),
});

export const FormWorkflowsSearch = ({ setQuery }: IProps) => {
  const handleSubmit = async (data: IFormWorkflowSearch) => {
    setQuery(data);
  };

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormWorkflowsSearchValues}
      validationSchema={formValidationSchema}
    >
      <Row
        gutter={styles.gutter.default}
        align="bottom"
      >
        <Col flex="auto">
          <Row gutter={styles.gutter.default}>
            <Col span={12}>
              <FieldsSelect
                label={t('WorkflowsSearch.Labels.ClientType')}
                name="type"
                options={[
                  {
                    value: EnumClientTypes.All,
                    label: t('Enums.ClientTypes.ALL'),
                  },
                  {
                    value: EnumClientTypes.Private,
                    label: t('Enums.ClientTypes.PCR'),
                  },
                  {
                    value: EnumClientTypes.Business,
                    label: t('Enums.ClientTypes.BCR'),
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <FieldsInput
                label={t('WorkflowsSearch.Labels.SearchTerm')}
                name="searchTerm"
              />
            </Col>
          </Row>
        </Col>
        <Col flex="none">
          <Button
            icon={<SearchOutlined />}
            type="primary"
            htmlType="submit"
            className="mb-4"
          >
            {t('WorkflowsSearch.Buttons.Submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
