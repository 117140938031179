import { DeleteOutlined, DownloadOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space } from 'antd';

import { styles } from '../../consts';
import { Date, FileSizeDisplay } from '../../shared';

interface IProps {
  id: string;
  name: string;
  size: number;
  author: string;
  date: string;
  onDelete?: (id: string) => void;
  onDownload?: (id: string) => void;
}

export const CardFile = ({ id, name, size, author, date, onDelete, onDownload }: IProps) => {
  return (
    <Card
      key={id}
      className="mb-2"
    >
      <Row
        gutter={styles.gutter.default}
        align="middle"
      >
        <Col flex="none">
          <FileOutlined />
        </Col>
        <Col flex="auto">
          <div className="font-semibold">{name}</div>
          <FileSizeDisplay
            className="text-muted"
            bytes={size}
          />
        </Col>
        <Col
          flex="none"
          className="text-end"
        >
          <div>{author}</div>
          <Date
            value={date}
            className="text-muted"
          />
        </Col>
        <Col flex="none">
          <Space>
            {typeof onDelete === 'function' && (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onDelete(id)}
              />
            )}
            {typeof onDownload === 'function' && (
              <Button
                icon={<DownloadOutlined />}
                onClick={() => onDownload(id)}
              >
                Download
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
