import { DownOutlined, FolderOutlined } from '@ant-design/icons';
import { Input, Tree, TreeDataNode } from 'antd';
import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { servicesClassifiers } from '../../services';

interface ExtendedTreeDataNode extends TreeDataNode {
  label: string;
  type: string;
  options?: string;
}

interface IProps {
  handleSelect: (label: Key, value: string, type: string, options?: string) => void;
}

export const ModalClassifiersPicker = ({ handleSelect }: IProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [treeData, setTreeData] = useState<ExtendedTreeDataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { data, isFetched } = servicesClassifiers.getClassifiers();

  const { t } = useTranslation();

  const treeDataClassifiers = useMemo(() => {
    return (
      data?.data?.map((entity) => ({
        title: entity.name,
        label: entity.name,
        key: entity.slug,
        type: '',
        options: undefined,
        selectable: false,
        icon: <FolderOutlined />,
        children: entity.children?.map((group) => ({
          title: group.name,
          label: group.name,
          key: `${entity.slug}.${group.slug}`,
          type: '',
          options: undefined,
          selectable: false,
          children: group.children?.map((classifier) => ({
            title: classifier.name,
            label: `${entity.name} > ${group.name} > ${classifier.name}`,
            type: classifier.type,
            options: 'options',
            key: `${entity.slug}.${group.slug}.${classifier.slug}`,
          })),
        })),
      })) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (isFetched) {
      setTreeData(treeDataClassifiers);
    }
  }, [isFetched, treeDataClassifiers]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      if (!value) {
        setTreeData(treeDataClassifiers);
        setExpandedKeys([]);
        return;
      }

      const filteredData = treeDataClassifiers
        .map((group) => ({
          ...group,
          children: group.children
            ?.map((subGroup) => ({
              ...subGroup,
              children: subGroup.children?.filter((classifier) => {
                const match = classifier.title.toLowerCase().includes(value.toLowerCase());
                if (match) setExpandedKeys((prev) => [...prev, subGroup.key]);
                return match;
              }),
            }))
            .filter((subGroup) => subGroup.children && subGroup.children.length > 0),
        }))
        .filter((group) => group.children && group.children.length > 0);

      setTreeData(filteredData ?? []);
    },
    [treeDataClassifiers],
  );

  return (
    <div>
      <div className="mb-4">
        <Input.Search
          placeholder={t('ModalClassifiersPicker.Labels.Placeholder')}
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          allowClear
        />
      </div>
      <Tree
        showIcon
        showLine
        switcherIcon={<DownOutlined />}
        treeData={treeData}
        expandedKeys={expandedKeys}
        autoExpandParent
        onExpand={(keys) => setExpandedKeys(keys as string[])}
        onSelect={(_, { node }: { node: ExtendedTreeDataNode }) => {
          console.log(node);
          handleSelect(node.key, node.label, node.type, node.options);
        }}
      />
    </div>
  );
};
