import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { styles } from 'src/consts';
import { FieldsInput, Form } from 'src/shared';

export const FormEntity = () => {
  const { t } = useTranslation();
  const handleSubmit = async (data: any) => {
    console.log(data);
  };

  const validationSchema = z.object({
    email: z.string().trim().min(10),
    password: z.string().trim().min(1, t('Validations.Required')),
  });
  return (
    <Form
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{
        email: 'email',
        password: 'password',
      }}
    >
      <Typography.Title
        level={5}
        className="mb-2"
      >
        {t('FormEntity.Titles.Basic')}
      </Typography.Title>
      <Row gutter={styles.gutter.default}>
        <Col span={12}>
          <FieldsInput
            label={t('FormEntity.Labels.Name')}
            name="name"
          />
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
      >
        {t('FormEntity.Buttons.Submit')}
      </Button>
    </Form>
  );
};
