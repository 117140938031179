import { useEffect, useRef } from 'react';

import { helpersAxios, helpersAxiosFiles } from '../../helpers';
import { servicesAuth } from '../../services';

const REFRESH_INTERVAL = 300_000;
const MAX_RETRY_DELAY = 3_600_1000;

export const TokenRefresher = () => {
  const retryCount = useRef(0);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const { mutateAsync, isPending } = servicesAuth.postRefresh();

  const refreshToken = async () => {
    if (isPending) {
      return;
    }
    try {
      const res = await mutateAsync();
      const newToken = res.data.access_token;

      helpersAxios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      helpersAxiosFiles.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      sessionStorage.setItem('token', newToken);

      console.log('Token refreshed successfully');
      retryCount.current = 0;
      scheduleNextRefresh(REFRESH_INTERVAL);
    } catch (error) {
      console.error('Failed to refresh token:', error);
      retryWithBackoff();
    }
  };

  const retryWithBackoff = () => {
    const delay = Math.min(Math.pow(2, retryCount.current) * 1000, MAX_RETRY_DELAY);
    retryCount.current++;
    scheduleNextRefresh(delay);
  };

  const scheduleNextRefresh = (delay: number) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(refreshToken, delay);
  };

  useEffect(() => {
    scheduleNextRefresh(REFRESH_INTERVAL);

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        refreshToken();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/token-refresh-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return null;
};
