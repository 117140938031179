import { App, Button, Col, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { styles } from 'src/consts';
import { components } from 'src/interfaces';
import { servicesClients } from 'src/services';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from 'src/shared';

interface IProps {
  data: components['schemas']['ClientIndexResource'];
}

export const FormClientBusinessEdit = ({ data }: IProps) => {
  const { mutateAsync } = servicesClients.putClient();
  const { t } = useTranslation();
  const { message } = App.useApp();

  const handleSubmit = async (values: components['schemas']['ClientIndexResource']) => {
    try {
      await mutateAsync({ id: parseInt(data.id), data: values });
      message.success(t('Messages.ClientUpdated'));
    } catch (e) {
      console.log(e);
    }
  };

  const validationSchema = z.object({
    type: z.string().trim().min(1),
    businessClient: z.object({
      title: z.string().trim().min(1),
      registrationDate: z.string().trim().min(1),
      businessType: z.string().trim().min(1),
      industry: z.string().trim().min(1),
    }),
  });

  return (
    <Form
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={data}
    >
      {({ formState }) => (
        <>
          <Typography.Title
            level={5}
            className="mb-2"
          >
            {t('FormClientBusiness.Titles.Basic')}
          </Typography.Title>
          <Row gutter={styles.gutter.default}>
            <Col span={12}>
              <FieldsInput
                label={t('FormClientBusiness.Labels.Title')}
                name="businessClient.title"
              />
            </Col>
            <Col span={12}>
              <FieldsDate
                label={t('FormClientBusiness.Labels.Date')}
                name="businessClient.registrationDate"
              />
            </Col>
            <Col span={24}>
              <FieldsSelect
                label={t('FormClientBusiness.Labels.BusinessType')}
                name="businessClient.businessType"
                options={[
                  {
                    label: 'UAB',
                    value: 'uab',
                  },
                  {
                    label: 'MB',
                    value: 'mb',
                  },
                ]}
              />
            </Col>
            <Col span={24}>
              <FieldsSelect
                label={t('FormClientBusiness.Labels.Industry')}
                name="businessClient.industry"
                options={[
                  {
                    label: 'laivyba',
                    value: 'vk1',
                  },
                  {
                    label: 'zvejyba',
                    value: 'vk2',
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <FieldsInput
                label={t('FormClientBusiness.Labels.CompanyCode')}
                name="businessClient.companyCode"
              />
            </Col>
          </Row>
          <Flex justify="end">
            <Button
              type="primary"
              htmlType="submit"
              loading={formState.isSubmitting}
            >
              {t('FormClientBusiness.Buttons.Submit')}
            </Button>
          </Flex>
        </>
      )}
    </Form>
  );
};
