export const apiPaths = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    refresh: 'auth/refresh',
    me: 'auth/me',
  },
  clients: {
    getClients: (queryString: string) => `clients?${queryString}`,
    getClient: (clientId: number) => `clients/${clientId}`,
    postClient: 'clients',
    putClient: (clientId: number) => `clients/${clientId}`,
    deleteClients: (clientId: number) => `clients/${clientId}`,
  },
  templates: {
    getTemplates: (queryString: string) => `templates?${queryString}`,
    getTemplate: (templateId: number, templateVersion: string | null) => `templates/${templateId}/${templateVersion}`,
    postTemplates: 'templates',
    putTemplates: (templateId: number) => `templates/${templateId}`,
    deleteTemplates: (templateId: number) => `templates/${templateId}`,
  },
  workflows: {
    getWorkflows: (queryString: string) => `workflows?${queryString}`,
    getWorkflow: (workflow: number, workflowVersion: string) => `workflows/${workflow}/${workflowVersion}`,
    postWorkflows: 'workflows',
    putWorkflows: (workflowId: number) => `workflows/${workflowId}`,
    deleteWorkflows: (workflowId: number) => `workflows/${workflowId}`,
  },
  schemas: {
    getSchemas: (queryString: string) => `schemas?${queryString}`,
    getSchema: (schemaId: number, schemaVersion: string) => `schemas/${schemaId}/${schemaVersion}`,
    postSchemas: 'schemas',
    putSchemas: (schemaId: number) => `schemas/${schemaId}`,
    deleteSchema: (schemaId: number) => `schemas/${schemaId}`,
  },
  permissions: {
    getPermissions: 'permissions',
  },
  users: {
    getUsers: 'users',
  },
  profile: {
    getShow: 'profile/show',
    postUpdate: 'profile/update',
    postTeamSwitch: 'profile/team/switch',
  },
  classifiers: {
    getClassifiers: 'classifiers',
    postClassifier: 'classifiers',
  },
  selections: {
    getSelections: 'selections',
    getSelection: (slug: string) => `selections/${slug}`,
  },
};
