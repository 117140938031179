import { Modal } from 'antd';

import { FormClassifier } from '../FormClassifier';

interface IProps {
  open: boolean;
  handleClose: () => void;
  data: {
    entity: string | null;
    group: string | null;
    parent_id: number | null;
    name: string | null;
    type: string | null;
    slug?: string | null;
    selection: string | null;
  };
}

export const ModalClassifier = ({ open, data, handleClose }: IProps) => {
  return (
    <Modal
      footer={null}
      open={open}
      onCancel={handleClose}
      title={data.slug ? 'update' : 'create'}
    >
      <FormClassifier
        data={data}
        handleClose={handleClose}
      />
    </Modal>
  );
};
