import { Col, Row, Typography } from 'antd';
import * as z from 'zod';

import { styles } from '../../consts';
import { FieldsCheckbox, FieldsSelect, Form } from '../../shared';

interface IProps {
  data: any;
  users: any;
}

export const FormUsersPermissions = ({ data, users }: IProps) => {
  const handleSubmit = async (data: any) => {
    console.log(data);
  };

  const formValidationSchema = z.object({
    type: z.string().optional(),
    searchTerm: z.string().optional(),
  });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={data}
      validationSchema={formValidationSchema}
    >
      <Row gutter={styles.gutter.default}>
        {data.groups.map((item: any, indexGroup: number) => (
          <Col
            key={indexGroup}
            span={6}
          >
            <Typography.Text className="block">{item.name}</Typography.Text>
            {item.permissions.map((item: any, index: number) => (
              <FieldsCheckbox
                name={`groups.${indexGroup}.permissions.${index}.value`}
                key={index}
              >
                {item.label}
              </FieldsCheckbox>
            ))}
          </Col>
        ))}
        <Col span={24}>
          <FieldsSelect
            name="users"
            options={users?.map((item: any) => ({
              label: `${item.name} ${item.surname}`,
              value: item.id,
            }))}
            mode="multiple"
          />
        </Col>
      </Row>
    </Form>
  );
};
