import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { CardDocument, CardFile } from '../../components';
import { routerPaths } from '../../consts';
import { ContentHeader, FileUpload } from '../../shared';

export const PagesWorkflowsPreview = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ContentHeader
        title="Vairuotojo darbinimas - Petraitis Antanas"
        onBack={() => navigate(routerPaths.workflows.index)}
      />
      <Typography.Paragraph>Darbo eiga skirta darbinti zmogu i vairuotojo pozija</Typography.Paragraph>
      <Typography.Title level={4}>Documents</Typography.Title>
      <CardDocument
        className="mb-3"
        data={{
          id: 'id',
          author: 'Petraitis Antanas',
          title: 'Darbo sutartis',
          status: 'success',
        }}
        onClick={() => null}
      />
      <CardDocument
        className="mb-3"
        data={{
          id: 'id',
          author: 'Petraitis Antanas',
          title: 'Konfidicialumo sutartis',
          status: 'pending',
        }}
        onClick={() => null}
      />
      <CardDocument
        className="mb-3"
        data={{
          id: 'id',
          author: 'Petraitis Antanas',
          title: 'Darbo saugos taisykles',
          status: 'sign',
        }}
        onClick={() => null}
      />
      <Typography.Title level={4}>Bylos</Typography.Title>
      <Typography.Title level={5}>Sveikatos pazymejimas</Typography.Title>
      <Typography.Paragraph>reikalingas aukstalipio darbui</Typography.Paragraph>
      <CardFile
        id={''}
        name={'Pazymejimas.jpg'}
        size={12}
        author={'Admin'}
        date={'2012-12-12'}
      />
      <Typography.Title level={5}>Passport</Typography.Title>
      <Typography.Paragraph>Passport for registration in vmi reikalingas is abieju pusiu</Typography.Paragraph>
      <CardFile
        id={''}
        name={'Passport.jpg'}
        size={12}
        author={'Admin'}
        date={'2012-12-12'}
      />
      <FileUpload multiple={true} />
      <Typography.Title level={5}>Soc draudimas</Typography.Title>
      <Typography.Paragraph>Sodrai reikalingas dokumentas</Typography.Paragraph>
      <FileUpload />
      <Typography.Title level={5}>Vairuotojo pazymejimas</Typography.Title>
      <Typography.Paragraph>reikalingas vairuoti CE</Typography.Paragraph>
      <FileUpload multiple={true} />
    </div>
  );
};
