import { EnumClientTypes } from '../../enums';

export interface IFormWorkflowSearch {
  searchTerm: string;
  type: string;
}

export const initialFormWorkflowsSearchValues: IFormWorkflowSearch = {
  searchTerm: '',
  type: EnumClientTypes.All,
};
