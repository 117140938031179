interface IObject {
  [key: string]: unknown;
}

export const getNestedObject = <T>(object: IObject, path: string): T | undefined => {
  return path.split('.').reduce<unknown>((acc, part) => {
    if (acc && typeof acc === 'object' && part in acc) {
      return (acc as IObject)[part];
    }
    return undefined;
  }, object) as T;
};
