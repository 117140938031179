import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiClassifiersResponse = operations['classifiers.index']['responses']['200']['content']['application/json']['data'];

const useGetClassifiers = () => {
  return useQuery<AxiosResponse<TApiClassifiersResponse>, AxiosError>({
    queryKey: [queryKeys.classifiers.index],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.classifiers.getClassifiers);

      return res.data;
    },
  });
};

export type TApiClassifierCreateRequest = operations['classifiers.store']['requestBody']['content']['application/json'];
type TApiClassifierCreateResponse = operations['classifiers.store']['responses']['200']['content']['application/json'];

const useClassifier = () => {
  return useMutation<AxiosResponse<TApiClassifierCreateResponse>, AxiosError, TApiClassifierCreateRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiClassifierCreateResponse>> => await helpersAxios.post(apiPaths.classifiers.postClassifier, data),
  });
};

export const servicesClassifiers = {
  getClassifiers: useGetClassifiers,
  postClassifier: useClassifier,
};
