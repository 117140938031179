import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { routerPaths } from './consts';
import { AuthProvider } from './contexts';
import { helpersAxios } from './helpers';
import { AppRoutes } from './routes';

NProgress.configure({ showSpinner: false });

export default function App() {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const notificationApi = useMemo(() => api, [api]);

  useEffect(() => {
    helpersAxios.interceptors.request.use((config) => {
      NProgress.start();
      return config;
    });

    const interceptor = helpersAxios.interceptors.response.use(
      (response) => {
        NProgress.done();

        return response;
      },
      (error: AxiosError) => {
        NProgress.done();

        if (error.status !== 401 && error.status !== 422) {
          notificationApi.error({
            type: 'error',
            message: 'Error',
            description: error.message,
          });
        }

        if (error.status === 401) {
          navigate(routerPaths.logout);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      helpersAxios.interceptors.response.eject(interceptor);
    };
  }, []);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
          },
        },
      }),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
      {contextHolder}
    </QueryClientProvider>
  );
}
