import { Outlet, Route, Routes } from 'react-router-dom';

import { routerPaths } from '../consts';
import { LayoutAuthMain } from '../layouts';
import {
  PagesClassifiers,
  PagesClients,
  PagesClientsCreate,
  PagesClientsPreview,
  PagesDashboard,
  PagesEmailTemplates,
  PagesLogin,
  PagesLogout,
  PagesPermissions,
  PagesSchemas,
  PagesSchemasCreate,
  PagesSchemasEdit,
  PagesSchemasPreview,
  PagesSelections,
  PagesSettings,
  PagesSettingsProfile,
  PagesTemplates,
  PagesTemplatesCreate,
  PagesTemplatesEdit,
  PagesUsers,
  PagesUsersPreview,
  PagesWorkFlows,
  PagesWorkflowsPreview,
} from '../pages';
import { PagesSettingsEntity } from '../pages/SettingsEntity';
import { RoutesAuth } from './Auth';
import { RoutesPublic } from './Public';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={routerPaths.logout}
        element={<PagesLogout />}
      />
      <Route element={<RoutesAuth children={<Outlet />} />}>
        <Route element={<LayoutAuthMain />}>
          <Route
            path={routerPaths.dashboard}
            element={<PagesDashboard />}
          />
          <Route
            path={routerPaths.clients.index}
            element={<Outlet />}
          >
            <Route
              index={true}
              element={<PagesClients />}
            />
            <Route
              path={routerPaths.clients.create}
              element={<PagesClientsCreate />}
            />
            <Route
              path={routerPaths.clients.preview}
              element={<PagesClientsPreview />}
            />
          </Route>
          <Route
            path={routerPaths.templates.index}
            element={<Outlet />}
          >
            <Route
              index={true}
              element={<PagesTemplates />}
            />
            <Route
              path={routerPaths.templates.create}
              element={<PagesTemplatesCreate />}
            />
            <Route
              path={routerPaths.templates.edit}
              element={<PagesTemplatesEdit />}
            />
          </Route>
          <Route
            path={routerPaths.workflows.index}
            element={<Outlet />}
          >
            <Route
              index={true}
              path={routerPaths.workflows.index}
              element={<PagesWorkFlows />}
            />
            <Route
              path={routerPaths.workflows.preview}
              element={<PagesWorkflowsPreview />}
            />
          </Route>
          <Route
            path={routerPaths.schemas.index}
            element={<Outlet />}
          >
            <Route
              index={true}
              path={routerPaths.schemas.index}
              element={<PagesSchemas />}
            />
            <Route
              path={routerPaths.schemas.create}
              element={<PagesSchemasCreate />}
            />
            <Route
              path={routerPaths.schemas.edit}
              element={<PagesSchemasEdit />}
            />
            <Route
              path={routerPaths.schemas.preview}
              element={<PagesSchemasPreview />}
            />
          </Route>
          <Route
            path={routerPaths.classifiers}
            element={<PagesClassifiers />}
          />
          <Route
            path={routerPaths.selections}
            element={<PagesSelections />}
          />
          <Route
            path={routerPaths.users.index}
            element={<Outlet />}
          >
            <Route
              index={true}
              path={routerPaths.users.index}
              element={<PagesUsers />}
            />
            <Route
              path={routerPaths.users.preview}
              element={<PagesUsersPreview />}
            />
          </Route>
          <Route
            path={routerPaths.settings.index}
            element={<PagesSettings />}
          >
            <Route
              path={routerPaths.settings.profile}
              element={<PagesSettingsProfile />}
            />
            <Route
              path={routerPaths.settings.entity}
              element={<PagesSettingsEntity />}
            />
          </Route>
          <Route
            path={routerPaths.permissions}
            element={<PagesPermissions />}
          />
          <Route
            path={routerPaths.email}
            element={<PagesEmailTemplates />}
          />
          <Route
            path="*"
            element={<PagesDashboard />}
          />
        </Route>
      </Route>
      <Route element={<RoutesPublic children={<Outlet />} />}>
        <Route
          path={routerPaths.login}
          element={<PagesLogin />}
        />
      </Route>
    </Routes>
  );
};
