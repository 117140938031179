import { PlusOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseSelections } from '../../components';
import { ModalSelections } from '../../components/ModalSelections';
import { servicesSelections } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesSelections = () => {
  const { t } = useTranslation();
  const { data, isFetching } = servicesSelections.getSelections();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <ContentHeader
        title={t('Selections.Title')}
        extra={
          <Button
            onClick={() => setIsOpen(true)}
            icon={<PlusOutlined />}
          >
            {t('Selections.Buttons.AddSelection')}
          </Button>
        }
      />
      {isFetching ? <Skeleton /> : <CollapseSelections data={data?.data} />}
      <ModalSelections
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};
