import { Navigate } from 'react-router-dom';

import { routerPaths } from '../../consts';
import { useAuth } from '../../contexts';

interface PublicRouteProps {
  children: JSX.Element | JSX.Element[];
}

export const RoutesPublic = ({ children }: PublicRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <Navigate
        to={routerPaths.dashboard}
        replace={true}
      />
    );
  }

  return children;
};
