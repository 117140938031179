import { Tag, theme } from 'antd';

interface IProps {
  status: 'success' | 'pending' | 'sign';
}

export const TagDocumentStatus = ({ status }: IProps) => {
  const { token } = theme.useToken();

  switch (status) {
    case 'pending':
      return <Tag color={token.colorWarning}>{status}</Tag>;
    case 'success':
      return <Tag color={token.colorSuccess}>{status}</Tag>;
    case 'sign':
      return <Tag color={token.colorPrimary}>{status}</Tag>;
    default:
      return null;
  }
};
