import { EditOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Row, Select, Skeleton, Typography } from 'antd';
import 'ckeditor5/ckeditor5.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import { CKEditorCustom } from 'src/components';
import { routerPaths, styles } from 'src/consts';
import { TApiTemplateUpdateRequest, servicesTemplate } from 'src/services';
import { BlockError, Date, FieldsInput, FieldsSelect, FieldsTextarea, Form } from 'src/shared';

const TemplateSchema = z.object({
  title: z.string().trim().min(2).max(255),
  content: z.string(),
  description: z.string().trim().min(2).max(600),
  meta: z.object({
    signature_type: z.enum(['none', 'manual', 'electronic', 'both']),
  }),
});

export const PagesTemplatesEdit = () => {
  const { id = '', version = '' } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { mutateAsync } = servicesTemplate.putTemplate();
  const { data, isFetching, error } = servicesTemplate.getTemplate({
    template: parseInt(id),
    version_id: version,
  });
  const { t } = useTranslation();

  const handleChangeVersion = (version: string) => {
    navigate(routerPaths.templates.editReplace.replace(':id', id).replace(':version', version));
  };

  const handleSubmit = async (values: TApiTemplateUpdateRequest['data']) => {
    try {
      await mutateAsync({
        data: values,
        id: parseInt(id),
      });
      message.success(t('Messages.TemplateUpdated'));
      navigate(routerPaths.templates.index);
    } catch (e) {
      console.log(e);
    }
  };

  if (isFetching) {
    return <Skeleton loading={true} />;
  }

  if (error) {
    return <BlockError error={error} />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        title: data?.data?.title,
        description: data?.data?.description,
        content: data?.data?.content ?? '',
        meta: {
          // @ts-ignore
          signature_type: data?.data?.meta?.signature_type,
        },
      }}
      validationSchema={TemplateSchema}
    >
      {({ formState }) => (
        <Row
          gutter={styles.gutter.default}
          className="h-full"
          align="stretch"
          wrap={false}
        >
          <Col flex="auto">
            <div className="mb-3">
              <div>
                <Typography.Text type="secondary">Created by: </Typography.Text>
                <Typography.Text>
                  {data?.data.creator.name} {data?.data.creator.surname} <Date value={data?.data.createdAt} />
                </Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">Updated by: </Typography.Text>
                <Typography.Text>
                  {data?.data.updater.name} {data?.data.updater.surname} <Date value={data?.data.updatedAt} />
                </Typography.Text>
              </div>
            </div>
            <Card className="mb-4">
              <Row gutter={styles.gutter.default}>
                <Col flex="auto">
                  <FieldsInput
                    name="title"
                    prefix={<EditOutlined />}
                    size="large"
                    variant="borderless"
                    placeholder={t('TemplatesCreate.Labels.PlaceHolder')}
                    className="font-bold"
                    wrapperClassName="mb-0"
                  />
                </Col>
                <Col>
                  <Select
                    onChange={(value) => handleChangeVersion(value)}
                    value={data?.data?.version_id}
                    style={{ width: 230 }}
                    options={data?.data.versions.map((item) => ({
                      label: (
                        <>
                          Ver.{item.label}, <Date value={item.createdAt} />
                        </>
                      ),
                      value: item.id,
                    }))}
                  />
                </Col>
              </Row>
            </Card>
            <Card>
              <div className="main-container">
                <CKEditorCustom name="content" />
              </div>
            </Card>
          </Col>
          <Col style={{ width: 320, minWidth: 320 }}>
            <Card className="h-full">
              <FieldsSelect
                name="meta.signature_type"
                options={[
                  { label: t('TemplatesCreate.Options.None'), value: 'none' },
                  { label: t('TemplatesCreate.Options.Manual'), value: 'manual' },
                  { label: t('TemplatesCreate.Options.Electronic'), value: 'electronic' },
                  { label: t('TemplatesCreate.Options.Both'), value: 'both' },
                ]}
                label={t('TemplatesCreate.Labels.SignMethod')}
              />
              <FieldsTextarea
                name="description"
                autoSize={{ minRows: 3, maxRows: 3 }}
                label={t('TemplatesCreate.Labels.Description')}
                maxLength={600}
              />
              <Button
                type="primary"
                block={true}
                htmlType="submit"
                loading={formState.isSubmitting}
              >
                {t('TemplatesCreate.Buttons.Submit')}
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </Form>
  );
};
