import { FileAddOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormSchemaSearch, TableSchemas } from '../../components';
import { queryKeys, routerPaths } from '../../consts';
import { servicesSchemas } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesSchemas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState({
    current: 1,
    page: 1,
    pageSize: 10,
    filter: {
      title: '',
    },
  });
  const { data, isFetching } = servicesSchemas.getSchemas({ ...query, page: query.current });

  const handleClick = (item: any) => {
    navigate(routerPaths.schemas.edit.replace(':id', item.id).replace(':version', item.versionId ?? ''));
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.schemas.index],
    });
  }, [query, queryClient]);

  return (
    <div>
      <ContentHeader
        title={t('Schemas.Title')}
        extra={
          <Button
            icon={<FileAddOutlined />}
            onClick={() => navigate(routerPaths.schemas.create)}
          >
            {t('Schemas.Buttons.CreateNew')}
          </Button>
        }
      />
      <FormSchemaSearch />
      <TableSchemas
        data={data?.data?.data}
        onClick={handleClick}
        isLoading={isFetching}
        pagination={query}
        onPaginationChange={(pagination) =>
          setQuery((prevState) => ({
            ...prevState,
            ...pagination,
          }))
        }
      />
    </div>
  );
};
