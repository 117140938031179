import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { clsx } from 'clsx';
import { useController } from 'react-hook-form';

import { getNestedObject } from '../../../helpers';
import { FieldsError } from '../Error.tsx';
import { FieldsLabel } from '../Label.tsx';

interface IPros extends TextAreaProps {
  name: string;
  label?: string;
  wrapperClassName?: string;
}

export const FieldsTextarea = ({ name, label, wrapperClassName, ...restProps }: IPros) => {
  const { field, formState } = useController({
    name: name,
    shouldUnregister: true,
  });

  const error = getNestedObject<{ message: string } | undefined>(formState.errors, name);

  return (
    <div className={clsx('mb-4', wrapperClassName)}>
      <FieldsLabel label={label} />
      <Input.TextArea
        ref={field.ref}
        onChange={(e) => field.onChange(e.target.value)}
        value={field.value}
        status={error !== undefined ? 'error' : ''}
        disabled={formState.isSubmitting}
        {...restProps}
      />
      <FieldsError error={error?.message} />
    </div>
  );
};
