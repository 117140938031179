export const routerPaths = {
  login: '/',
  logout: '/logout',
  dashboard: '/dashboard',
  clients: {
    index: '/clients',
    create: '/clients/create',
    preview: '/clients/preview/:id',
  },
  templates: {
    index: '/templates',
    create: 'create',
    edit: 'edit/:id/:version',
    editReplace: '/templates/edit/:id/:version',
  },
  classifiers: '/classifiers',
  users: {
    index: '/users',
    preview: '/users/preview/:id',
  },
  settings: {
    index: '/settings',
    profile: '/settings/profile',
    entity: '/settings/entity',
  },
  schemas: {
    index: '/schemas',
    create: '/schemas/create',
    preview: '/schemas/preview/:id',
    edit: '/schemas/edit/:id/:version',
  },
  workflows: {
    index: '/workflows',
    preview: '/workflows/preview/:id',
  },
  permissions: '/permissions',
  email: '/email',
  selections: '/selections',
};
