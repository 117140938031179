import { Skeleton } from 'antd';
import { useParams } from 'react-router-dom';

import { servicesSchemas } from '../../services';
import { BlockError } from '../../shared';

export const PagesSchemasEdit = () => {
  const { id = '', version = '' } = useParams();
  const { data, isFetching, error } = servicesSchemas.getSchema({
    schemaId: parseInt(id),
    schemaVersion: version,
  });

  if (isFetching) {
    return <Skeleton loading={true} />;
  }

  if (error) {
    return <BlockError error={error} />;
  }

  return (
    <div>
      PagesSchemasEdit
      <pre>{JSON.stringify(data, undefined, 4)}</pre>
    </div>
  );
};
