import { FileAddOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormTemplateSearch, TableTemplates } from 'src/components';
import { queryKeys, routerPaths } from 'src/consts';
import { servicesTemplate } from 'src/services/';
import { ContentHeader } from 'src/shared';

import { components } from '../../interfaces';

export const PagesTemplates = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 10,
    filter: {
      title: '',
    },
  });

  const { data, isFetching } = servicesTemplate.getTemplates(query);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.templates.index],
    });
  }, [query, queryClient]);

  const handleClick = (item: components['schemas']['TemplateIndexResource']) => {
    navigate(routerPaths.templates.edit.replace(':id', item.id).replace(':version', item.versionId ?? ''));
  };

  console.log(query);

  return (
    <div>
      <ContentHeader
        title={t('Templates.Title')}
        extra={
          <Button
            icon={<FileAddOutlined />}
            onClick={() => navigate(routerPaths.templates.create)}
          >
            {t('Templates.Buttons.CreateNew')}
          </Button>
        }
      />
      <FormTemplateSearch setQuery={(data) => setQuery((prevState) => ({ ...prevState, ...data }))} />
      <TableTemplates
        isLoading={isFetching}
        data={data?.data?.data}
        onClick={handleClick}
        pagination={{
          ...query,
          total: data?.data.pagination.total,
        }}
        onPaginationChange={(pagination) =>
          setQuery((prevState) => ({
            ...prevState,
            ...pagination,
          }))
        }
      />
    </div>
  );
};
