import { App as AppANTD, ConfigProvider } from 'antd';
import 'antd/es/style/reset.css';
import 'nprogress/nprogress.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { configTheme } from './configs';
import './i18n/config.ts';
import './styles/main.scss';
import './styles/overides.scss';
import './styles/pdf-viewer.scss';

createRoot(document.getElementById('root')!).render(
  <BrowserRouter future={{ v7_startTransition: true }}>
    <ConfigProvider theme={configTheme}>
      <AppANTD>
        <App />
      </AppANTD>
    </ConfigProvider>
  </BrowserRouter>,
);
