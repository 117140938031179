import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiSelectionsResponse = operations['selections.index']['responses']['200']['content']['application/json']['data'];

const useGetSelections = () => {
  return useQuery<AxiosResponse<TApiSelectionsResponse>, AxiosError>({
    queryKey: [queryKeys.selections.index],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.selections.getSelections);

      return res.data;
    },
  });
};

export type TApiSelectionResponse = operations['selections.show']['responses']['200']['content']['application/json']['data'];

const useGetSelection = (slug: string) => {
  return useQuery<AxiosResponse<TApiSelectionResponse>, AxiosError>({
    queryKey: [queryKeys.selections.show, slug],
    queryFn: async () => {
      const res = await helpersAxios(apiPaths.selections.getSelection(slug));

      return res.data;
    },
  });
};

export const servicesSelections = {
  getSelections: useGetSelections,
  getSelection: useGetSelection,
};
