import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiWorkflowsRequestQuery = operations['workflows.index']['parameters']['query'];
export type TApiWorkflowsResponse = operations['workflows.index']['responses']['200']['content']['application/json'];

const useGetWorkflows = (query: TApiWorkflowsRequestQuery) => {
  return useQuery<AxiosResponse<TApiWorkflowsResponse>, AxiosError>({
    queryKey: [queryKeys.workflows.index],
    queryFn: () => helpersAxios(apiPaths.workflows.getWorkflows(qs.stringify(query))),
  });
};

export type TApiWorkflowRequest = operations['workflows.show']['parameters']['path'];
export type TApiWorkflowResponse = operations['workflows.show']['responses']['200']['content'];

const useGetWorkflow = ({ workflow, workflowVersion }: TApiWorkflowRequest) => {
  return useQuery<AxiosResponse<TApiWorkflowResponse>, AxiosError>({
    queryKey: [queryKeys.workflows.show, workflow, workflowVersion],
    queryFn: async () => await helpersAxios(apiPaths.workflows.getWorkflow(workflow, workflowVersion)),
  });
};

export type TApiWorkflowCreateRequest = operations['workflows.store']['requestBody']['content']['application/json'];
type TApiWorkflowCreateResponse = operations['workflows.store']['responses']['201']['content']['application/json'];

const usePostWorkflow = () => {
  return useMutation<AxiosResponse<TApiWorkflowCreateResponse>, AxiosError, TApiWorkflowCreateRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiWorkflowCreateResponse>> => await helpersAxios.post(apiPaths.workflows.postWorkflows, data),
  });
};

export type TApiWorkflowUpdateRequest = {
  data: operations['workflows.update']['requestBody']['content']['application/json'];
  id: operations['workflows.update']['parameters']['path']['workflow'];
};
type TApiWorkflowUpdateResponse = operations['workflows.update']['responses']['200']['content'];

const usePutWorkflow = () => {
  return useMutation<AxiosResponse<TApiWorkflowUpdateResponse>, AxiosError, TApiWorkflowUpdateRequest>({
    mutationFn: async ({ id, data }): Promise<AxiosResponse<TApiWorkflowUpdateResponse>> => await helpersAxios.put(apiPaths.workflows.putWorkflows(id), data),
  });
};
type TApiWorkflowDeleteRequest = operations['workflows.destroy']['parameters']['path']['workflow'];
type TApiWorkflowDeleteResponse = operations['workflows.destroy']['responses']['200']['content'];

const useDeleteWorkflow = () => {
  return useMutation<AxiosResponse<TApiWorkflowDeleteResponse>, AxiosError, TApiWorkflowDeleteRequest>({
    mutationFn: async (id): Promise<AxiosResponse<TApiWorkflowDeleteResponse>> => await helpersAxios.delete(apiPaths.workflows.deleteWorkflows(id)),
  });
};

export const servicesWorkflows = {
  getWorkflows: useGetWorkflows,
  getWorkflow: useGetWorkflow,
  postWorkflow: usePostWorkflow,
  putWorkflow: usePutWorkflow,
  deleteWorkflow: useDeleteWorkflow,
};
