import { UserAddOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormClientSearch, TableClients } from '../../components';
import { queryKeys, routerPaths } from '../../consts';
import { servicesClients } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesClients = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    filter: {
      title: '',
    },
  });

  const { data, isFetching } = servicesClients.getClients(query);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.clients.index],
    });
  }, [query, queryClient]);

  const handleClick = (id: string) => {
    navigate(routerPaths.clients.preview.replace(':id', id));
  };

  return (
    <div>
      <ContentHeader
        title={t('Clients.Title')}
        extra={
          <Button
            icon={<UserAddOutlined />}
            onClick={() => navigate(routerPaths.clients.create)}
          >
            {t('Clients.Buttons.CreateNew')}
          </Button>
        }
      />
      <FormClientSearch />
      <TableClients
        data={data?.data?.data}
        isLoading={isFetching}
        onClick={handleClick}
        pagination={{
          ...query,
          total: data?.data.pagination.total,
        }}
        onPaginationChange={(pagination) =>
          setQuery((prevState) => ({
            ...prevState,
            ...pagination,
          }))
        }
      />
    </div>
  );
};
