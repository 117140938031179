import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { styles } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { FieldsInput, FieldsSelect, Form } from '../../shared';
import { IFormTemplateSearch, initialFormTemplateSearchValues } from './interface';

interface IProps {
  setQuery: Dispatch<SetStateAction<IFormTemplateSearch>>;
}

const formValidationSchema = z.object({
  filter: z.object({
    type: z.string().optional(),
    title: z.string().optional(),
    owner: z.string().optional(),
  }),
});

export const FormTemplateSearch = ({ setQuery }: IProps) => {
  const handleSubmit = async (data: IFormTemplateSearch) => {
    setQuery(data);
  };

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormTemplateSearchValues}
      validationSchema={formValidationSchema}
    >
      <Row
        gutter={styles.gutter.default}
        align="bottom"
      >
        <Col flex="auto">
          <Row gutter={styles.gutter.default}>
            <Col span={8}>
              <FieldsSelect
                label={t('TemplatesSearch.Labels.ClientType')}
                name="filter.type"
                options={[
                  {
                    value: EnumClientTypes.All,
                    label: t('Enums.ClientTypes.ALL'),
                  },
                  {
                    value: EnumClientTypes.Private,
                    label: t('Enums.ClientTypes.PCR'),
                  },
                  {
                    value: EnumClientTypes.Business,
                    label: t('Enums.ClientTypes.BCR'),
                  },
                ]}
              />
            </Col>
            <Col span={8}>
              <FieldsSelect
                label={t('TemplatesSearch.Labels.Owner')}
                name="filter.owner"
                options={[]}
              />
            </Col>
            <Col span={8}>
              <FieldsInput
                label={t('TemplatesSearch.Labels.Title')}
                name="filter.title"
              />
            </Col>
          </Row>
        </Col>
        <Col flex="none">
          <Button
            icon={<SearchOutlined />}
            type="primary"
            htmlType="submit"
            className="mb-4"
          >
            {t('TemplatesSearch.Buttons.Submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
