interface IProps {
  bytes?: number;
  decimals?: number;
  className?: string;
}

export const FileSizeDisplay = ({ bytes, decimals = 2, className }: IProps) => {
  if (!bytes) {
    return null;
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const readableValue = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));

  return (
    <span className={className}>
      {readableValue} {sizes[i]}
    </span>
  );
};
