import { useQueryClient } from '@tanstack/react-query';
import { App, Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '../../consts';
import { useAuth } from '../../contexts';
import { EnumSelectionTypes } from '../../enums';
import { useSelections } from '../../hooks';
import { TApiProfileUpdateRequest, servicesProfile } from '../../services';
import { FieldsDate, FieldsInput, FieldsSelect, Form } from '../../shared';

export const FormProfile = () => {
  const { auth } = useAuth();
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { mutateAsync } = servicesProfile.postUpdate();
  const [isEditable, setIsEditable] = useState(false);
  const { t } = useTranslation();
  const { getSelections, getSelectionsLoading } = useSelections();

  const handleSubmit = async (values: TApiProfileUpdateRequest) => {
    try {
      await mutateAsync(values);
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.profile.show],
      });

      message.success(t('Messages.ProfileUpdated'));

      setIsEditable(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form
      initialValues={{
        name: auth?.data?.name,
        surname: auth?.data?.surname,
        personal_code: auth?.data?.personal_code,
        citizenship: auth?.data?.citizenship,
        date_of_birth: auth?.data?.date_of_birth,
        email: auth?.data?.email,
      }}
      onSubmit={handleSubmit}
    >
      {({ formState }) => (
        <>
          <FieldsInput
            name="name"
            label={t('FormProfile.Labels.Name')}
          />
          <FieldsInput
            name="surname"
            label={t('FormProfile.Labels.Surname')}
          />
          <FieldsInput
            name="personal_code"
            label={t('FormProfile.Labels.PersonalCode')}
          />
          <FieldsSelect
            name="citizenship"
            label={t('FormProfile.Labels.Citizenship')}
            options={getSelections(EnumSelectionTypes.countries)}
            loading={getSelectionsLoading.includes(EnumSelectionTypes.countries)}
          />
          <FieldsDate
            name="date_of_birth"
            label={t('FormProfile.Labels.BirthDate')}
          />
          <FieldsInput
            name="email"
            label={t('FormProfile.Labels.Email')}
          />
          <Button.Group>
            <Button
              disabled={formState.isSubmitting}
              onClick={() => setIsEditable((prevState) => !prevState)}
            >
              {isEditable ? t('FormProfile.Buttons.Cancel') : t('FormProfile.Buttons.Edit')}
            </Button>
            {isEditable && (
              <Button
                disabled={!formState.isDirty}
                loading={formState.isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {t('FormProfile.Buttons.Submit')}
              </Button>
            )}
          </Button.Group>
        </>
      )}
    </Form>
  );
};
