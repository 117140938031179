import { EnumClientTypes } from '../../enums';

export interface IFormClientSearch {
  searchTerm: string;
  type: string;
}

export const initialFormClientSearchValues: IFormClientSearch = {
  searchTerm: '',
  type: EnumClientTypes.All,
};
