import { DeleteOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, Button, PaginationProps, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { queryKeys } from '../../consts';
import { components } from '../../interfaces';
import { servicesClients } from '../../services';
import { Date } from '../../shared';

interface IProps {
  data: any;
  isLoading: boolean;
  onClick: (id: string) => void;
  pagination: PaginationProps;
  onPaginationChange: (pagination: PaginationProps) => void;
}

export const TableClients = ({ data, isLoading, onClick, pagination, onPaginationChange }: IProps) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { mutateAsync } = servicesClients.deleteClient();

  const handleDelete = (item: components['schemas']['ClientIndexResource']) => {
    modal.confirm({
      title: t('Confirms.Titles.DeleteClient'),
      content: t('Confirms.Contents.DeleteClient{{value}}', {
        value: item.businessClient.title || item.privateClient.name + ' ' + item.privateClient.surname,
      }),
      onOk: async () => {
        try {
          await mutateAsync(parseInt(item.id));
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.clients.index],
          });
          message.success(t('Messages.ClientDeleted'));
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  const columns: TableProps<components['schemas']['ClientIndexResource']>['columns'] = [
    {
      title: t('Clients.Labels.Type'),
      key: 'type',
      dataIndex: 'type',
      render: (value) => (value === 'private' ? <UserOutlined /> : <TeamOutlined />),
    },
    {
      title: t('Clients.Labels.Title'),
      render: (_, row) =>
        row.type === 'private' ? (
          <div>
            {row.privateClient.name} {row.privateClient.surname}
          </div>
        ) : (
          row.businessClient.title
        ),
    },
    {
      title: t('Clients.Labels.Workflows'),
      key: 'workflows',
      dataIndex: 'workflows',
    },
    {
      title: t('Clients.Labels.Date'),
      render: (_, row) =>
        row.type === 'private' ? (
          <Date
            format="YYYY-MM-DD"
            value={row.privateClient.dateOfBirth}
          />
        ) : (
          <Date
            format="YYYY-MM-DD"
            value={row.businessClient.registrationDate}
          />
        ),
    },
    {
      title: t('Clients.Labels.DateCreated'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (value) => <Date value={value} />,
    },
    {
      title: t('Clients.Labels.Actions'),
      render: (_: any, row: any) =>
        row.readOnly ? null : (
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(row);
            }}
          />
        ),
      align: 'right',
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={data}
      loading={isLoading}
      onRow={(record) => {
        return {
          onClick: () => onClick(record.id),
        };
      }}
      pagination={{ ...pagination, showSizeChanger: true }}
      onChange={(pagination) => onPaginationChange(pagination)}
    />
  );
};
