import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translationsZodEn from 'zod-i18n-map/locales/en/zod.json';
import translationsZodLt from 'zod-i18n-map/locales/lt/zod.json';

import translationEn from './translations/gb.json';
import translationLt from './translations/lt.json';

const currentLanguage: string = window.localStorage.getItem('language') ?? import.meta.env.VITE_DEFAULT_LANGUAGE;

void i18next.use(initReactI18next).init(
  {
    lng: currentLanguage,
    resources: {
      gb: {
        zod: translationsZodEn,
        translation: translationEn,
      },
      lt: {
        zod: translationsZodLt,
        translation: translationLt,
      },
    },
    fallbackLng: 'gb',
    react: {
      useSuspense: true,
    },
  },
  (error) => {
    if (error) {
      console.error('something went wrong loading translation', error);
    }
  },
);

z.setErrorMap(zodI18nMap);
