export const getTemplateClassifiers = (htmlString: string, cleanDuplicates: boolean = true) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const classifierSpans = doc.querySelectorAll('span.classifier');

  const classifiers = Array.from(classifierSpans).map((span) => ({
    classifier: span.getAttribute('data-classifier') || '',
    label: span.getAttribute('data-label') || '',
    type: span.getAttribute('data-type') || '',
    options: span.getAttribute('data-options') || null,
  }));

  if (cleanDuplicates) {
    const uniqueClassifiers = new Map<string, { label: string; type: string; options: string | null }>();

    classifiers.forEach(({ classifier, label, type, options }) => {
      if (!uniqueClassifiers.has(classifier)) {
        uniqueClassifiers.set(classifier, { label, type, options });
      }
    });

    return Array.from(uniqueClassifiers, ([classifier, { label, type, options }]) => ({
      classifier,
      label,
      type,
      options,
    }));
  }

  return classifiers;
};
