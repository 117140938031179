import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormWorkflowsSearch, TableWorkflows } from '../../components';
import { queryKeys, routerPaths } from '../../consts';
import { components } from '../../interfaces';
import { servicesWorkflows } from '../../services';
import { ContentHeader } from '../../shared';

export const PagesWorkFlows = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [query, setQuery] = useState({
    current: 1,
    pageSize: 10,
    filter: {
      title: '',
    },
  });

  const { data, isFetching } = servicesWorkflows.getWorkflows(query);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.workflows.index],
    });
  }, [query, queryClient]);

  const handleClick = (item: components['schemas']['WorkflowIndexResource']) => {
    navigate(routerPaths.workflows.preview.replace(':id', item.id));
  };

  return (
    <div>
      <ContentHeader title={t('Workflows.Title')} />
      <FormWorkflowsSearch setQuery={(data) => setQuery((prevState) => ({ ...prevState, ...data }))} />
      <div className="mb-4">
        <TableWorkflows
          isLoading={isFetching}
          data={data?.data?.data}
          onClick={handleClick}
          pagination={{
            ...query,
            // @ts-ignore
            total: data?.data.pagination.total,
          }}
          onPaginationChange={(pagination) =>
            setQuery((prevState) => ({
              ...prevState,
              ...pagination,
            }))
          }
        />
      </div>
    </div>
  );
};
