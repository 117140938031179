import { useQuery } from '@tanstack/react-query';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';

const useGetUsers = () => {
  return useQuery({
    queryKey: [queryKeys.users.index],
    queryFn: async () => await helpersAxios(apiPaths.users.getUsers),
  });
};

export const servicesUsers = {
  getUsers: useGetUsers,
};
