import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';

const useGetSchemas = (query: any) => {
  return useQuery<AxiosResponse<any>, AxiosError>({
    queryKey: [queryKeys.schemas.index],
    queryFn: () => helpersAxios(apiPaths.schemas.getSchemas(qs.stringify(query))),
  });
};

const useGetSchema = ({ schemaId, schemaVersion }: any) => {
  return useQuery<AxiosResponse<any>, AxiosError>({
    queryKey: [queryKeys.schemas.show, schemaId, schemaVersion],
    queryFn: async () => await helpersAxios(apiPaths.schemas.getSchema(schemaId, schemaVersion)),
  });
};

const usePostSchema = () => {
  return useMutation<AxiosResponse<any>, AxiosError, any>({
    mutationFn: async (data): Promise<AxiosResponse<any>> => await helpersAxios.post(apiPaths.schemas.postSchemas, data),
  });
};

const usePutSchema = () => {
  return useMutation<AxiosResponse<any>, AxiosError, any>({
    mutationFn: async ({ id, data }): Promise<AxiosResponse<any>> => await helpersAxios.put(apiPaths.schemas.putSchemas(id), data),
  });
};

const useDeleteSchema = () => {
  return useMutation<AxiosResponse<any>, AxiosError, any>({
    mutationFn: async (id): Promise<AxiosResponse<any>> => await helpersAxios.delete(apiPaths.schemas.deleteSchema(id)),
  });
};

export const servicesSchemas = {
  getSchemas: useGetSchemas,
  getSchema: useGetSchema,
  postSchema: usePostSchema,
  putSchema: usePutSchema,
  deleteSchema: useDeleteSchema,
};
