import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { apiPaths } from '../../consts';
import { helpersAxios, helpersAxiosPublic } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiAuthLoginRequestBody = operations['auth.login']['requestBody']['content']['application/json'];
type TApiAuthLoginResponse = operations['auth.login']['responses']['200']['content']['application/json'];
type TApiAuthLoginResponseError = operations['auth.login']['responses']['422']['content']['application/json'];

const usePostLogin = () => {
  return useMutation<AxiosResponse<TApiAuthLoginResponse>, AxiosError<TApiAuthLoginResponseError>, TApiAuthLoginRequestBody>({
    mutationFn: async (data): Promise<AxiosResponse<TApiAuthLoginResponse>> => {
      return await helpersAxiosPublic.post(apiPaths.auth.login, data);
    },
  });
};

type TApiAuthLogoutResponse = operations['auth.logout']['responses']['200']['content']['application/json'];

export const usePostLogout = () => {
  return useMutation<AxiosResponse<TApiAuthLogoutResponse>, AxiosError>({
    mutationFn: async (): Promise<AxiosResponse<TApiAuthLogoutResponse>> => await helpersAxios.post(apiPaths.auth.logout),
  });
};

type TApiAuthRefreshResponse = operations['auth.refresh']['responses']['200']['content']['application/json'];

const usePostRefresh = () => {
  return useMutation<AxiosResponse<TApiAuthRefreshResponse>, AxiosError>({
    mutationFn: async (): Promise<AxiosResponse<TApiAuthRefreshResponse>> => await helpersAxios.post(apiPaths.auth.refresh),
  });
};

export const servicesAuth = {
  postLogin: usePostLogin,
  postLogout: usePostLogout,
  postRefresh: usePostRefresh,
};
