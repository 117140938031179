import { Card, Radio, RadioChangeEvent } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormClientBusiness, FormClientPrivate } from '../../components';
import { routerPaths } from '../../consts';
import { EnumClientTypes } from '../../enums';
import { ContentHeader } from '../../shared';

export const PagesClientsCreate = () => {
  const [formType, setFormType] = useState(EnumClientTypes.Private);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleChangeForm = (e: RadioChangeEvent) => {
    setFormType(e.target.value);
  };

  return (
    <div>
      <ContentHeader
        title={t('ClientsRegistration.Title')}
        onBack={() => navigate(routerPaths.clients.index)}
      />
      <div className="max-w-screen-sm mx-auto mb-4">
        <Radio.Group
          onChange={handleChangeForm}
          value={formType}
          className="w-full"
          size="large"
        >
          <Radio.Button
            className="w-1/2 text-center"
            value={EnumClientTypes.Private}
          >
            {t('ClientsRegistration.Person')}
          </Radio.Button>
          <Radio.Button
            className="w-1/2 text-center"
            value={EnumClientTypes.Business}
          >
            {t('ClientsRegistration.Business')}
          </Radio.Button>
        </Radio.Group>
      </div>
      <Card className="max-w-screen-sm mx-auto">
        {formType === EnumClientTypes.Private && <FormClientPrivate />}
        {formType === EnumClientTypes.Business && <FormClientBusiness />}
      </Card>
    </div>
  );
};
