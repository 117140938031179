import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';
import { operations } from '../../interfaces';

export type TApiProfileShowResponse = operations['profile.show']['responses']['200']['content']['application/json'];

const useGetShow = () => {
  return useQuery<AxiosResponse<TApiProfileShowResponse>, AxiosError>({
    queryKey: [queryKeys.profile.show],
    queryFn: async () => await helpersAxios(apiPaths.profile.getShow),
  });
};

export type TApiProfileUpdateRequest = operations['profile.update']['requestBody']['content']['application/json'];
type TApiProfileUpdateResponse = operations['profile.update']['responses']['200']['content']['application/json'];

const usePostUpdate = () => {
  return useMutation<AxiosResponse<TApiProfileUpdateResponse>, AxiosError, TApiProfileUpdateRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiProfileUpdateResponse>> => await helpersAxios.post(apiPaths.profile.postUpdate, data),
  });
};

type TApiProfileTeamSwitchRequest = operations['profile.team.switch']['requestBody']['content']['application/json'];
type TApiProfileTeamSwitchResponse = operations['profile.team.switch']['responses']['200']['content']['application/json'];

const usePostTeamSwitch = () => {
  return useMutation<AxiosResponse<TApiProfileTeamSwitchResponse>, AxiosError, TApiProfileTeamSwitchRequest>({
    mutationFn: async (data): Promise<AxiosResponse<TApiProfileTeamSwitchResponse>> => await helpersAxios.post(apiPaths.profile.postUpdate, data),
  });
};

export const servicesProfile = {
  getShow: useGetShow,
  postUpdate: usePostUpdate,
  postTeamSwitch: usePostTeamSwitch,
};
