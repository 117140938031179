import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Skeleton } from 'antd';
import { ClassicEditor } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { configEditor } from 'src/configs';

import { getTemplateClassifiers } from '../../helpers';

interface IProps {
  name: string;
}

export const CKEditorCustom = ({ name }: IProps) => {
  const { field } = useController({
    name: name,
    shouldUnregister: true,
  });
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  if (!isLayoutReady) {
    return <Skeleton />;
  }

  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        value={field.value}
        // @ts-ignore
        config={{ ...configEditor, initialData: field.value }}
        onChange={(_event, editor) => field.onChange(editor.getData())}
        onReady={(editor) => field.onChange(editor.getData())}
      />
      <pre className="block py-8 text-wrap">{JSON.stringify(getTemplateClassifiers(field.value), undefined, 2)}</pre>
    </>
  );
};
