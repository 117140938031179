import { Modal } from 'antd';

import { FormSelections } from '../FormSelections';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export const ModalSelections = ({ open, handleClose }: IProps) => {
  return (
    <Modal
      title={'Modal Selections'}
      open={open}
      footer={null}
      onCancel={handleClose}
    >
      <FormSelections handleClose={handleClose} />
    </Modal>
  );
};
