import { useQuery } from '@tanstack/react-query';

import { apiPaths, queryKeys } from '../../consts';
import { helpersAxios } from '../../helpers';

const useGetPermissions = () => {
  return useQuery({
    queryKey: [queryKeys.permissions.index],
    queryFn: async () => await helpersAxios(apiPaths.permissions.getPermissions),
  });
};

export const servicesPermissions = {
  getPermissions: useGetPermissions,
};
