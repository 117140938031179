import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { FormSchemaCreate } from '../../components';
import { routerPaths } from '../../consts';
import { ContentHeader } from '../../shared';

export const PagesSchemasCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <ContentHeader
        title={t('SchemaCreate.Title')}
        onBack={() => navigate(routerPaths.schemas.index)}
      />
      <FormSchemaCreate />
    </div>
  );
};
