import { Avatar, Space, Typography } from 'antd';

import { useAuth } from '../../contexts';

export const LayoutUser = () => {
  const { auth } = useAuth();

  return (
    <Space
      align="center"
      className=" leading-4"
    >
      <Avatar>{auth?.data?.name.substring(0, 1)}</Avatar>
      <div>
        <Typography.Text
          className="block leading-4"
          strong={true}
        >
          {auth?.data?.name}
        </Typography.Text>
        <Typography.Text
          className="block leading-4"
          type="secondary"
        >
          {auth?.data?.email}
        </Typography.Text>
      </div>
    </Space>
  );
};
