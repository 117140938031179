import { Button, Card, Col, Divider, Flex, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FormLogin } from '../../components';
import { routerPaths, styles } from '../../consts';

export const PagesLogin = () => {
  const { t } = useTranslation();

  return (
    <Layout className="min-h-dvh">
      <Layout.Content className="min-h-dvh flex items-center">
        <div className="container mx-auto">
          <Card>
            <Row gutter={styles.gutter.default}>
              <Col
                xs={24}
                md={7}
              >
                <Typography.Title
                  level={5}
                  className="mb-4"
                >
                  {t('Login.Labels.Login')}
                </Typography.Title>
                <div className="mb-4">
                  <FormLogin />
                </div>
                <Typography.Paragraph type="secondary">
                  {t('Login.Labels.Remember')}
                  {''}
                  <Link
                    type="link"
                    to={routerPaths.login}
                  >
                    here
                  </Link>
                </Typography.Paragraph>
                <Divider>
                  <Typography.Text type="secondary">{t('Login.Labels.Signup')}</Typography.Text>
                </Divider>
                <Flex
                  vertical={true}
                  gap="middle"
                >
                  <Button
                    size="large"
                    block={true}
                  >
                    {t('Login.Buttons.Google')}
                  </Button>
                  <Button
                    size="large"
                    block={true}
                  >
                    {t('Login.Buttons.Facebook')}
                  </Button>
                </Flex>
              </Col>
              <Col
                xs={24}
                md={17}
              >
                <Typography.Title>{t('Login.Labels.Title')}</Typography.Title>
                <Typography.Paragraph>{t('Login.Labels.Subtitle')}</Typography.Paragraph>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout.Content>
    </Layout>
  );
};
