import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { routerPaths } from '../../consts';
import { ContentHeader } from '../../shared';

export const PagesSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const items: TabsProps['items'] = [
    {
      key: routerPaths.settings.profile,
      label: t('Settings.Labels.Profile'),
    },
    {
      key: routerPaths.settings.entity,
      label: t('Settings.Labels.Entity'),
    },
  ];
  return (
    <div>
      <ContentHeader title={t('Settings.Title')} />
      <Tabs
        items={items}
        type="card"
        onChange={(key) => navigate(key)}
        activeKey={location.pathname}
      />
      <Outlet />
    </div>
  );
};
